//TODO ADD ERROR HANDLING

import { CameraActionType, CameraActions, CameraProperties, CustomerCamerasState } from './types';
import { combineReducers } from 'redux';
import produce from 'immer';

const initialState: Record<string, CameraProperties> = {};

const cameras = produce((draft, action: CameraActions) => {
  switch (action.type) {
    case CameraActionType.DELETE_CAMERA_SUCCESS:
      delete draft[action.successResponse.camera_id];
      return draft;
    case CameraActionType.GET_CAMERAS_LIST_SUCCESS:
      action.successResponse.forEach((camera) => {
        draft[camera.camera_id] = camera;
      });
      return draft;
    case CameraActionType.CREATE_CAMERA_SUCCESS:
      draft[action.successResponse.camera_id] = action.successResponse;
      return draft;
    case CameraActionType.EDIT_CAMERA_SUCCESS:
      draft[action.successResponse.camera_id] = action.successResponse;
      return draft;
  }
}, initialState);

export const editingCamera = (state = null, action: CameraActions): CameraProperties => {
  switch (action.type) {
    case CameraActionType.SET_CAMERA_FOR_EDIT:
      return action.camera;
    case CameraActionType.CLEAR_CAMERA_FROM_EDIT:
      return null;
    default:
      return state;
  }
};

export const cameraReducer = combineReducers<CustomerCamerasState>({
  cameras,
  editingCamera,
});
