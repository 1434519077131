import { ActionFactory } from 'data-handling/adapters/redux';

import { CameraActionType } from './types';

import * as Types from './types';

export const createCameraRequest: ActionFactory<void, Types.CreateCameraRequestAction> = () => ({
  type: CameraActionType.CREATE_CAMERA_REQUEST,
});

export const createCameraSuccess: ActionFactory<any, Types.CreateCameraSuccessAction> = (
  successResponse
) => ({
  type: CameraActionType.CREATE_CAMERA_SUCCESS,
  successResponse,
});

export const createCameraFailure: ActionFactory<any, Types.CreateCameraFailureAction> = (
  errorResponse
) => ({
  type: CameraActionType.CREATE_CAMERA_FAILURE,
  errorResponse,
});

export const editCameraRequest: ActionFactory<void, Types.EditCameraRequestAction> = () => ({
  type: CameraActionType.EDIT_CAMERA_REQUEST,
});

export const editCameraSuccess: ActionFactory<any, Types.EditCameraSuccessAction> = (
  successResponse
) => ({
  type: CameraActionType.EDIT_CAMERA_SUCCESS,
  successResponse,
});

export const editCameraFailure: ActionFactory<any, Types.EditCameraFailureAction> = (
  errorResponse
) => ({
  type: CameraActionType.EDIT_CAMERA_FAILURE,
  errorResponse,
});

export const getCameraListRequest: ActionFactory<void, Types.GetCameraListRequestAction> = () => ({
  type: CameraActionType.GET_CAMERAS_LIST_REQUEST,
});

export const getCameraListSuccess: ActionFactory<any, Types.GetCameraListSuccessAction> = (
  successResponse
) => ({
  type: CameraActionType.GET_CAMERAS_LIST_SUCCESS,
  successResponse,
});

export const getCameraFailure: ActionFactory<any, Types.GetCameraListFailureAction> = (
  errorResponse
) => ({
  type: CameraActionType.GET_CAMERAS_LIST_FAILURE,
  errorResponse,
});
//

export const deleteCameraRequest: ActionFactory<void, Types.DeleteRequestAction> = () => ({
  type: CameraActionType.DELETE_CAMERA_REQUEST,
});

export const deleteCameraSuccess: ActionFactory<any, Types.DeleteCameraSuccessAction> = (
  successResponse
) => ({
  type: CameraActionType.DELETE_CAMERA_SUCCESS,
  successResponse,
});

export const deleteCameraFailure: ActionFactory<any, Types.DeleteCameraFailureAction> = (
  errorResponse
) => ({
  type: CameraActionType.DELETE_CAMERA_FAILURE,
  errorResponse,
});

export const setCameraForEdit: ActionFactory<any, Types.SetCameraForEditAction> = (camera) => ({
  type: CameraActionType.SET_CAMERA_FOR_EDIT,
  camera,
});

export const clearCameraFromEdit: ActionFactory<void, Types.ClearCameraFromEditAction> = () => ({
  type: CameraActionType.CLEAR_CAMERA_FROM_EDIT,
});
