import { ActionFactory } from 'data-handling/adapters/redux';

import { VPNActionType } from './types';

import * as Types from './types';

export const editVPNRequest: ActionFactory<void, Types.EditVPNRequestAction> = () => ({
  type: VPNActionType.EDIT_VPN_REQUEST,
});

export const editVPNSuccess: ActionFactory<any, Types.EditVPNSuccessAction> = (
  successResponse
) => ({
  type: VPNActionType.EDIT_VPN_SUCCESS,
  successResponse,
});

export const editVPNFailure: ActionFactory<any, Types.EditVPNFailureAction> = (errorResponse) => ({
  type: VPNActionType.EDIT_VPN_FAILURE,
  errorResponse,
});

export const getVPNListRequest: ActionFactory<void, Types.GetVPNListRequestAction> = () => ({
  type: VPNActionType.GET_VPN_LIST_REQUEST,
});

export const getVPNListSuccess: ActionFactory<any, Types.GetVPNListSuccessAction> = (
  successResponse
) => ({
  type: VPNActionType.GET_VPN_LIST_SUCCESS,
  successResponse,
});

export const getVPNFailure: ActionFactory<any, Types.GetVPNListFailureAction> = (
  errorResponse
) => ({
  type: VPNActionType.GET_VPN_LIST_FAILURE,
  errorResponse,
});

export const setVPNForEdit: ActionFactory<Types.VPN, Types.SetVPNForEditAction> = (VPN) => ({
  type: VPNActionType.SET_VPN_FOR_EDIT,
  VPN,
});

export const clearVPNFromEdit: ActionFactory<void, Types.ClearVPNEditAction> = () => ({
  type: VPNActionType.CLEAR_VPN_FROM_EDIT,
});
