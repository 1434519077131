import { HttpService } from '../../httpService';
import { ActiveSessionClient } from './types';
import { ActiveSession } from 'data-handling/domain';

import { keycloak } from 'helpers';
import { ResponseSuccess } from 'data-handling/infrastracture';

const makeActiveSessionsApiHandling = ({
  httpService,
}: {
  httpService: HttpService;
}): ActiveSessionClient => {
  const getAllActiveSessions = async (): Promise<ResponseSuccess<ActiveSession[]>> => {
    const path = 'all-sessions';
    const token = keycloak.token;

    const allActiveSessions = await httpService.GET<ActiveSession[]>(
      `${process.env.REACT_APP_API_BASE_URL}/${path}`,
      token
    );
    return allActiveSessions;
  };

  const getActiveSessionsByGroupId = async ({
    groupId,
  }): Promise<ResponseSuccess<ActiveSession[]>> => {
    const path = 'group-sessions';
    const token = keycloak.token;

    const allGroups = await httpService.POST<ActiveSession[]>(
      `${process.env.REACT_APP_API_BASE_URL}/${path}`,
      token,
      { group_id: groupId }
    );
    return allGroups;
  };

  return { getAllActiveSessions, getActiveSessionsByGroupId };
};

export default makeActiveSessionsApiHandling;
