import { ActionFactory } from '../../../adapters/redux';

import {
  ActionType,
  ChangeLayoutAction,
  ChangeLayoutWidthAction,
  ChangeSidebarThemeAction,
  ChangeSidebarTypeAction,
  ToggleRightSidebarAction,
  ShowRightSidebarAction,
  HideRightSidebarAction,
} from './types';

export const changeLayout: ActionFactory<string, ChangeLayoutAction> = (width) => ({
  type: ActionType.CHANGE_LAYOUT,
  payload: width,
});

export const changeLayoutWidth: ActionFactory<string, ChangeLayoutWidthAction> = (width) => ({
  type: ActionType.CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeSidebarTheme: ActionFactory<string, ChangeSidebarThemeAction> = (theme) => ({
  type: ActionType.CHANGE_SIDEBAR_THEME,
  payload: theme,
});

export const changeSidebarType: ActionFactory<string, ChangeSidebarTypeAction> = (sidebarType) => ({
  type: ActionType.CHANGE_SIDEBAR_TYPE,
  payload: sidebarType,
});

export const toggleRightSidebar: ActionFactory<string, ToggleRightSidebarAction> = (
  sidebarType
) => ({
  type: ActionType.TOGGLE_RIGHT_SIDEBAR,
  payload: null,
});

export const showRightSidebar: ActionFactory<string, ShowRightSidebarAction> = () => ({
  type: ActionType.SHOW_RIGHT_SIDEBAR,
  payload: null,
});

export const hideRightSidebar: ActionFactory<string, HideRightSidebarAction> = () => ({
  type: ActionType.HIDE_RIGHT_SIDEBAR,
  payload: null,
});
