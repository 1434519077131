import { HttpService } from '../../httpService';
import { keycloak } from 'helpers';

import { CameraUser } from 'data-handling/domain';
import { CameraUserClient } from './types';
import { ResponseSuccess } from 'data-handling/infrastracture';
//TODO CHECK IF HTTP SERVICE RESPONSE SHOULD BE SOMETHING DIFFERENT THAN ANY
const makeCameraUserAPIHandling = ({
  httpService,
}: {
  httpService: HttpService;
}): CameraUserClient => {
  const createUser = async ({
    first,
    last,
    email,
    license_id,
    roles,
    customer_id,
  }: Partial<CameraUser>): Promise<ResponseSuccess<any>> => {
    const path = 'users/create';
    const token = keycloak.token;

    const response = await httpService.POST<any>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { first, last, roles, license_id, email, customer_id }
    );
    return response;
  };

  const updateUser = async ({
    first,
    last,
    email,
    license_id,
    roles,
    user_id,
    customer_id,
  }: Partial<CameraUser>): Promise<ResponseSuccess<any>> => {
    const path = 'users/update';
    const token = keycloak.token;

    const response = await httpService.POST<any>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { first, last, email, license_id, roles, user_id, customer_id }
    );
    return response;
  };

  const getUsersList = async ({ customerId }): Promise<ResponseSuccess<CameraUser[]>> => {
    const path = 'users/list';
    const token = keycloak.token;

    const response = await httpService.POST<CameraUser[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { customer_id: customerId }
    );
    return response;
  };

  const deleteUser = async ({ user_id }): Promise<ResponseSuccess<CameraUser[]>> => {
    const path = 'users/delete';
    const token = keycloak.token;

    const response = await httpService.POST<any>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { user_id }
    );
    return response;
  };

  return { createUser, getUsersList, updateUser, deleteUser };
};

export default makeCameraUserAPIHandling;
