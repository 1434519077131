import { Action } from 'redux';

export interface Group {
  id: string;
  name: string;
}
export enum GroupActionType {
  GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST',
  GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE',
  GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS',
}

export interface GetGroupsRequestAction extends Action<GroupActionType.GET_GROUPS_REQUEST> {}

export interface GetGroupsSuccessAction extends Action<GroupActionType.GET_GROUPS_SUCCESS> {
  readonly groups: Array<Group>;
}

export interface GetGroupsFailureAction extends Action<GroupActionType.GET_GROUPS_FAILURE> {}

export type GroupAction = GetGroupsRequestAction | GetGroupsSuccessAction | GetGroupsFailureAction;
