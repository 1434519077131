import { ErrorResponse } from './types';
const initState = {
  error: null,
};

export default function errorResponseReducer(state = initState, action: any): ErrorResponse {
  const { errorResponse } = action;

  if (errorResponse) {
    return {
      error: `${errorResponse} ${new Date()}`,
    };
  }
  return state;
}
