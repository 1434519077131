import React from 'react';

interface ErrorBoundaryProps {
  fallbackStrategy?: 'passthrough' | 'invisible' | 'placeholder';
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static defaultProps: Partial<ErrorBoundaryProps>;

  state = { hasError: false };

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    // Display fallback UI
    this.setState({ hasError: true });

    // send this error to our logging service.(currently we only console log this error)
    console.log(error, info);
  }

  render() {
    const { fallbackStrategy, children } = this.props;

    // if no error occurs -> render as normal
    if (!this.state.hasError) {
      return children;
    }

    // else decide what to show based on the selected strategy
    switch (fallbackStrategy) {
      case 'invisible':
        return null;
      case 'placeholder':
        return <div>Something went wrong</div>;
      case 'passthrough':
      default:
        return children;
    }
  }
}

export default ErrorBoundary;
