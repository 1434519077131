import { Action } from 'redux';

export enum ActionType {
  INIT_MENU = 'INIT_MENU',
  INIT_MENU_SUCCESS = 'INIT_MENU_SUCCESS',
  CHANGE_ACTIVE_MENU_FROM_LOCATION = 'CHANGE_ACTIVE_MENU_FROM_LOCATION',
  CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS = 'CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS',
}

export interface InitMenuAction extends Action<ActionType.INIT_MENU> {
  readonly payload?: any;
}

export interface InitMenuSuccessAction extends Action<ActionType.INIT_MENU_SUCCESS> {
  readonly payload?: any;
}

export interface ChangeActiveMenuFromLocationAction
  extends Action<ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION> {
  readonly payload?: any;
}

export interface ChangeActiveMenuFromLocationSuccessAction
  extends Action<ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS> {
  readonly payload?: any;
}

export type AppMenuAction =
  | InitMenuAction
  | ChangeActiveMenuFromLocationSuccessAction
  | ChangeActiveMenuFromLocationAction
  | InitMenuSuccessAction;
