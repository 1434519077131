import { HttpService } from '../../httpService';
import { keycloak } from 'helpers';

import { VpnClient } from './types';
import { ResponseSuccess } from 'data-handling/infrastracture';
import { VPN } from 'data-handling/domain';

const makeZeroTierAPIHandling = ({ httpService }: { httpService: HttpService }): VpnClient => {
  const getList = async ({ customerId }): Promise<ResponseSuccess<any[]>> => {
    const path = 'zt/list';
    const token = keycloak.token;

    const response = await httpService.POST<VPN[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { customer_id: customerId }
    );
    return response;
  };

  const update = async ({
    zt_node_id,
    camera_name,
    zt_node_association,
  }: {
    zt_node_id: string;
    camera_name: string;
    zt_node_association: string;
  }): Promise<ResponseSuccess<any>> => {
    const path = 'zt/update';
    const token = keycloak.token;

    const response = await httpService.POST<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { zt_node_id, camera_name, zt_node_association }
    );
    return response;
  };

  return { getList, update };
};

export default makeZeroTierAPIHandling;
