import { ThunkCommandFactory } from 'data-handling/adapters/redux';
import * as types from './types';
import * as actions from './actions';

import { convertValueFromSelectFieldToDTO } from 'helpers';
//TODO REFACTOR RESPONSE SUCCESS TYPE TO HAVE ARGUMENT DETAIL

// const defaultUsers = [
//   {
//     user_id: '514f4bfc-064d-4b6c-b600-afb665528f98',
//     first: 'tom4',
//     last: 'man 4',
//     email: 'tom4@gmail.com',
//     roles: [1],
//     license_id: [1],
//     customer_id: '6346d6ae-d65f-41a0-82df-5558a95a5298',
//   },
//   {
//     user_id: 'b52b52c7-9ecd-43ae-8326-8577f040c38f',
//     first: 'tom5',
//     last: 'man 5',
//     email: 'tom5@gmail.com',
//     roles: [1],
//     license_id: [1],
//     customer_id: '6346d6ae-d65f-41a0-82df-5558a95a5298',
//   },
// ];
export const getCameraUserList: ThunkCommandFactory<
  { customerId: string },
  | types.GetCameraUserListFailureAction
  | types.GetCameraUserListSuccessAction
  | types.GetCameraUserListRequestAction
> = ({ customerId }) => async (dispatch, _getState, client) => {
  dispatch(actions.getCameraUserListRequest());

  try {
    const response = (await client.cameraUserClient.getUsersList({ customerId })) as any;

    if (!response.detail) {
      dispatch(actions.getCameraUserListSuccess(response));
    } else {
      dispatch(actions.getCameraUserFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.getCameraUserFailure(err.message));
  }
};

export const createCameraUser: ThunkCommandFactory<
  types.CameraUser,
  | types.CreateCameraUserRequestAction
  | types.CreateCameraUserFailureAction
  | types.CreateCameraUserSuccessAction
> = (cameraUser) => async (dispatch, _getState, client) => {
  dispatch(actions.createCameraUserRequest());

  try {
    const response = (await client.cameraUserClient.createUser({
      ...cameraUser,
      license_id: convertValueFromSelectFieldToDTO(cameraUser.license_id),
      roles: convertValueFromSelectFieldToDTO(cameraUser.roles),
      customer_id: _getState().customers.editingCustomer.customer_id,
    })) as any;

    if (!response.detail) {
      //TODO CURRENTLLY THIS IS NOT NEEDED AS WE DONT HAVE THE RETURNED NEW USER
      // dispatch(actions.createCameraUserSuccess(response));
      getCameraUserList({ customerId: _getState().customers.editingCustomer.customer_id })(
        dispatch,
        null,
        client
      );
      clearCameraUserForEdit()(dispatch, null, null);
    } else {
      dispatch(actions.createCameraUserFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.createCameraUserFailure(err.message));
  }
};

export const editCameraUser: ThunkCommandFactory<
  types.CameraUser,
  | types.EditCameraUserFailureAction
  | types.EditCameraUserRequestAction
  | types.EditCameraUserSuccessAction
> = (cameraUser) => async (dispatch, _getState, client) => {
  dispatch(actions.editCameraUserRequest());
  try {
    //TODO SAVE AT REDUX THE LICENCES AND ROLES AS
    const response = (await client.cameraUserClient.updateUser({
      ...cameraUser,
      license_id: convertValueFromSelectFieldToDTO(cameraUser.license_id),
      roles: convertValueFromSelectFieldToDTO(cameraUser.roles),
      customer_id: _getState().customers.editingCustomer.customer_id,
    })) as any;

    if (!response.detail) {
      dispatch(actions.editCameraUserSuccess(cameraUser));
      clearCameraUserForEdit()(dispatch, null, null);
      getCameraUserList({ customerId: _getState().customers.editingCustomer.customer_id })(
        dispatch,
        null,
        client
      );
    } else {
      dispatch(actions.editCameraUserFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.editCameraUserFailure(err.message));
  }
};

export const deleteCameraUser: ThunkCommandFactory<
  { user_id },
  | types.DeleteCameraUserRequestAction
  | types.DeleteCameraUserFailureAction
  | types.DeleteCameraUserSuccessAction
> = ({ user_id }) => async (dispatch, _getState, client) => {
  dispatch(actions.deleteCameraUserRequest());
  try {
    const response = (await client.cameraUserClient.deleteUser({ user_id })) as any;

    if (!response.detail) {
      dispatch(actions.deleteCameraUserSuccess({ user_id }));
    } else {
      dispatch(actions.deleteCameraUserFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.deleteCameraUserFailure(err.message));
  }
};

export const setCameraUserForEdit: ThunkCommandFactory<
  types.CameraUser,
  types.setCameraUserForEditAction
> = (cameraUser) => async (dispatch, _getState, client) => {
  dispatch(actions.setCameraUserForEdit(cameraUser));
};

export const clearCameraUserForEdit: ThunkCommandFactory<
  void,
  types.ClearCameraUserEditAction
> = () => async (dispatch, _getState, client) => {
  dispatch(actions.clearCameraUserFromEdit());
};
