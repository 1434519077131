//TODO ADD ERROR HANDLING

import { CameraUserActionType, CameraUserActions, CameraUser, CameraUserState } from './types';
import { combineReducers } from 'redux';
import produce from 'immer';

const initialState: Record<string, CameraUser> = {};

const cameraUser = produce((draft, action: CameraUserActions) => {
  switch (action.type) {
    case CameraUserActionType.GET_CAMERA_USERS_LIST_SUCCESS:
      action.successResponse.forEach((cameraUser) => {
        draft[cameraUser.user_id] = cameraUser;
      });
      return draft;
    case CameraUserActionType.CREATE_CAMERA_USER_SUCCESS:
      draft[action.successResponse.user_id] = action.successResponse;
      return draft;
    case CameraUserActionType.EDIT_CAMERA_USER_SUCCESS:
      draft[action.successResponse.user_id] = action.successResponse;
      return draft;
    case CameraUserActionType.DELETE_CAMERA_USER_SUCCESS:
      delete draft[action.successResponse.user_id];
      return draft;
  }
}, initialState);

export const editingCameraUser = (state = null, action: CameraUserActions): CameraUser => {
  switch (action.type) {
    case CameraUserActionType.SET_CAMERA_USER_FOR_EDIT:
      return action.cameraUser;
    case CameraUserActionType.CLEAR_CAMERA_USER_FROM_EDIT:
      return null;
    default:
      return state;
  }
};

export const cameraUserReducer = combineReducers<CameraUserState>({
  cameraUser,
  editingCameraUser,
});
