import { Action } from 'redux';

export type Resources = {
  licences: Array<any>;
  roles: Array<any>;
};

export type ResourcesState = {
  resources: any;
};

export enum ResourceActionType {
  GET_LICENCES_AND_ROLES_REQUEST = 'GET_LICENCES_AND_ROLES_REQUEST',
  GET_LICENCES_AND_ROLES_SUCCESS = 'GET_LICENCES_AND_ROLES_SUCCESS',
  GET_LICENCES_AND_ROLES_FAILURE = 'GET_LICENCES_AND_ROLES_FAILURE',
}

export interface GetLicencesAndRolesRequestAction
  extends Action<ResourceActionType.GET_LICENCES_AND_ROLES_REQUEST> {}

export interface GetLicencesAndRolesSuccessAction
  extends Action<ResourceActionType.GET_LICENCES_AND_ROLES_SUCCESS> {
  readonly successResponse: Array<any>;
}

export interface GetLicencesAndRolesFailureAction
  extends Action<ResourceActionType.GET_LICENCES_AND_ROLES_FAILURE> {
  readonly errorResponse: any;
}

export type ResourcesActions =
  | GetLicencesAndRolesFailureAction
  | GetLicencesAndRolesSuccessAction
  | GetLicencesAndRolesRequestAction;
