import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { AppState } from '../../domain';
import * as infrastracture from '../../infrastracture';
import { ClientTypes } from 'data-handling/infrastracture';

import {
  loadingReducer,
  activeSessionsReducer,
  groupReducer,
  customerReducer,
  cameraUserReducer,
  cameraReducer,
  VPNReducer,
  resourcesReducer,
  errorResponseReducer,
} from 'data-handling/domain';
//TODO REMOVE UI REDUCERS FROM HERE,(SHOULD BE USED THROUGH CONTEXT)
import { layoutReducer } from 'data-handling/domain/ui/layout';
import { appMenuReducer } from 'data-handling/domain/ui/appMenu';

import sagas from 'data-handling/domain/sagas';

//TODO MAKE INFRASTRACTURE DEPEDENCY TO STORE
//TODO MAKE CONFIGURE STORE AS HIGH ORDER FUNCTION WILL ACCEPT AS ARGUMENTS THE REDUCERS AND EVERYTHING ELSE
export function configureStore({
  httpService,
}: {
  httpService: infrastracture.HttpService;
}): Store<AppState> {
  const activeSessionsClient = infrastracture.makeActiveSessionsAPIHandling({ httpService });
  const groupClient = infrastracture.makeGroupAPIHandling({ httpService });
  const customerClient = infrastracture.makeCustomerAPIHandling({ httpService });
  const cameraUserClient = infrastracture.makeCameraUserAPIHandling({ httpService });
  const cameraClient = infrastracture.makeCamerasAPIHandling({ httpService });
  const vpnClient = infrastracture.makeVPNAPIHandling({ httpService });
  const resourcesClient = infrastracture.makeResourcesAPIHandling({ httpService });

  const client: ClientTypes = {
    activeSessionsClient,
    groupClient,
    customerClient,
    cameraUserClient,
    cameraClient,
    vpnClient,
    resourcesClient,
  };
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk.withExtraArgument(client), sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  //TODO COMBINE REDUCER WITH CUSTOMERS AND CAMERA USERS
  const rootReducer = combineReducers<AppState>({
    activeSessions: activeSessionsReducer,
    Layout: layoutReducer,
    AppMenu: appMenuReducer,
    loading: loadingReducer,
    groups: groupReducer,
    customers: customerReducer,
    customerCamerasUsers: cameraUserReducer,
    customerCameras: cameraReducer,
    customerVPN: VPNReducer,
    resources: resourcesReducer,
    errorResponse: errorResponseReducer,
  });

  /* if (process.env.NODE_ENV !== 'production' && module.hot) {
      module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    } */

  const store = createStore(rootReducer, composedEnhancers);
  sagaMiddleware.run(sagas, {});
  return store;
}
