import { HttpService } from '../../httpService';
import { keycloak } from 'helpers';

import { ResourcesClient } from './types';
import { ResponseSuccess } from 'data-handling/infrastracture';

const makeResourcesAPIHandling = ({
  httpService,
}: {
  httpService: HttpService;
}): ResourcesClient => {
  const getLicenceAndRoles = async (): Promise<ResponseSuccess<any[]>> => {
    const path = 'license-roles';
    const token = keycloak.token;

    const response = await httpService.GET<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token
    );
    return response;
  };

  return { getLicenceAndRoles };
};

export default makeResourcesAPIHandling;
