// import Keycloak from 'keycloak-js';
import { keycloak } from '../helpers';
import React from 'react';
import { KeycloakInitOptions, KeycloakInstance, KeycloakTokenParsed } from 'keycloak-js';

type KeycloakServiceProps = {
  successCallback: (token: string, roles: string[]) => void;
  errorCallback?: () => void;
};

const useKeyCloak = ({
  successCallback,
  errorCallback,
}: KeycloakServiceProps): {
  init: (options: KeycloakInitOptions) => void;
  authClient: KeycloakInstance;
} => {
  React.useEffect(() => {
    keycloak.onReady = onLoginSuccess('onReady');
    keycloak.onAuthSuccess = onLoginSuccess('onAuthSuccess');
    keycloak.onAuthError = onLoginError('onAuthError');
    keycloak.onAuthRefreshSuccess = onLoginSuccess('onAuthRefreshSuccess');
    keycloak.onAuthRefreshError = onLoginError('onAuthRefreshError');
    // keycloak.onAuthLogout = this.updateState('onAuthLogout');
    keycloak.onTokenExpired = () => refreshToken();

    return () => {
      keycloak.onReady = undefined;
      keycloak.onAuthSuccess = undefined;
      keycloak.onAuthError = undefined;
      keycloak.onAuthRefreshSuccess = undefined;
      keycloak.onAuthRefreshError = undefined;
      keycloak.onAuthLogout = undefined;
      keycloak.onTokenExpired = undefined;
    };
  }, []);

  const init = (options: KeycloakInitOptions) => {
    keycloak.init({
      ...options,
      checkLoginIframe: false,
      flow: 'hybrid',
      onLoad: 'login-required',
    });
  };

  const refreshToken = () => {
    keycloak.updateToken(5);
  };

  const onLoginSuccess = (event: string) => () => {
    console.log(event);
    const token = keycloak.token;
    const tokenParsed = keycloak.tokenParsed;
    // const token = keycloak.tokenParsed as KeycloakTokenParsed;
    successCallback(token, tokenParsed.realm_access.roles);
  };

  const onLoginError = (event: string) => () => {
    console.log(event);
    errorCallback();
  };

  return { init, authClient: keycloak };
};

export default useKeyCloak;
