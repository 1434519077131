//TODO USE IMMER
//TODO ADD ERROR HANDLING
import { combineReducers } from 'redux';
import produce from 'immer';

import { VPNActionType, VPNActions, VPN, VPNState } from './types';

const initialState: Record<string, VPN> = {};

const vpn = produce((draft, action: VPNActions) => {
  switch (action.type) {
    case VPNActionType.GET_VPN_LIST_SUCCESS:
      action.successResponse.forEach((vpnItem) => {
        draft[vpnItem.zt_node_association] = vpnItem;
      });
      return draft;
    case VPNActionType.EDIT_VPN_SUCCESS:
      draft[action.successResponse.zt_node_association] = action.successResponse;
      return draft;
  }
}, initialState);

export const editingVPN = (state = null, action: VPNActions): VPN => {
  switch (action.type) {
    case VPNActionType.SET_VPN_FOR_EDIT:
      return action.VPN;
    case VPNActionType.CLEAR_VPN_FROM_EDIT:
      return null;
    default:
      return state;
  }
};

export const VPNReducer = combineReducers<VPNState>({
  editingVPN,
  vpn,
});
