import { createSelector, Selector } from 'reselect';
import { memoize, cloneDeep } from 'lodash';
//TODO ADD THE LICENCE IDS TO ROLE MAPPINGS ADD AN EXTRA VALUE OF ARGUMENTS TO convertValuefromDTOToSelectFieldView
import { AppState, CameraUser } from 'data-handling/domain';
import { convertValuefromDTOToSelectFieldView, convertObjectToArray } from 'helpers';
const ROLE_ID_MAPPINGS = [
  { value: 1, label: 'C2 Admin' },
  { value: 3, label: 'C2 User role' },
  { value: 4, label: 'C2 Live View Only' },
  { value: 2, label: 'C2 Sub-Operator' },
  { value: 7, label: 'Bodyworn Admin' },
  { value: 6, label: 'Bodyworn User' },
  { value: 8, label: 'Taglock Admin' },
  { value: 5, label: 'Taglock User' },
];

const LICENCE_MAPPINGS = [
  { value: 1, label: 'C2' },
  { value: 2, label: 'Bodyworn' },
  { value: 3, label: 'Taglock Mobile' },
];

export const cameraUsersSelector: Selector<
  AppState['customerCamerasUsers'],
  Record<string, CameraUser>
> = (cameraUser) => cameraUser.cameraUser;

export const editingCameraUserSelector: Selector<AppState['customerCamerasUsers'], CameraUser> = (
  cameraUser
) => cameraUser.editingCameraUser;

export const editingCameraUserWithView = createSelector<
  AppState['customerCamerasUsers'],
  CameraUser,
  any
>(editingCameraUserSelector, (editCameraUser) => {
  if (!editCameraUser) return null;
  const clone = cloneDeep(editCameraUser);

  return {
    ...clone,
    roles: convertValuefromDTOToSelectFieldView({
      mappings: ROLE_ID_MAPPINGS,
      value: editCameraUser.roles,
    }),
    license_id: convertValuefromDTOToSelectFieldView({
      mappings: LICENCE_MAPPINGS,
      value: editCameraUser.license_id,
    }),
  };
});

export const cameraUsersWithView = createSelector<
  AppState['customerCamerasUsers'],
  Record<string, CameraUser>,
  any
>(cameraUsersSelector, (cameraUsers) => {
  if (!cameraUsers) return [];
  const cloneCameraUser = cloneDeep(cameraUsers);

  const arrayOfCameraUsers = convertObjectToArray<CameraUser>(cloneCameraUser);

  return arrayOfCameraUsers.map((currentCameraUser) => {
    return {
      ...currentCameraUser,
      roles: convertValuefromDTOToSelectFieldView({
        mappings: ROLE_ID_MAPPINGS,
        value: currentCameraUser.roles,
      }),
      license_id: convertValuefromDTOToSelectFieldView({
        mappings: LICENCE_MAPPINGS,
        value: currentCameraUser.license_id,
      }),
    };
  });
});
