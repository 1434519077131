import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

const Alert = ({ error }: { error: string }): JSX.Element => {
  return (
    <React.Fragment>
      <div
        style={{
          position: 'fixed',
          width: '300px',
          zIndex: 1000,
          bottom: ' 2px',
          right: '23px',
        }}
      >
        <UncontrolledAlert color={'danger'}>{error}</UncontrolledAlert>
      </div>
    </React.Fragment>
  );
};

export default Alert;
