import { Action } from 'redux';

export type Customer = {
  customer_id?: string;
  customer_name: string;
  customer_street: string;
  customer_suite: string;
  customer_zip: number;
  customer_phone?: any;
  customer_website?: any;
  customer_poc_name: string;
  customer_poc_email: string;
  customer_poc_phone: string;
  customer_taxexempt: string;
  customer_ein?: string;
  new_cameras?: number;
  account_start_date: string | number;
  account_duration: number;
  account_total_contract: string | number;
  customer_generated_urls?: Record<string, any>;
  zt_network_id?: string;
  axisMSE?: 'string';
  recording_api?: 'string';
  ws1?: 'string';
  ws2?: 'string';
  ws3?: 'string';
  ws4?: 'string';
  customer_storage_size?: number;
  customer_storage_info?: string;
};

export type CustomerState = {
  customers: Record<string, Customer>;
  editingCustomer: Customer;
};

export enum CustomerActionType {
  CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE',
  CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS',

  EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST',
  EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE',
  EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS',

  GET_CUSTOMER_LIST_REQUEST = 'GET_CUSTOMER_LIST_REQUEST',
  GET_CUSTOMER_LIST_SUCCESS = 'GET_CUSTOMER_LIST_SUCCESS',
  GET_CUSTOMER_LIST_FAILURE = 'GET_CUSTOMER_LIST_FAILURE',

  GET_CUSTOMER_INFO_REQUEST = 'GET_CUSTOMER_INFO_REQUEST',
  GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS',
  GET_CUSTOMER_INFO_FAILURE = 'GET_CUSTOMER_INFO_FAILURE',

  SET_CUSTOMER_FOR_EDIT = 'SET_CUSTOMER_FOR_EDIT',
  CLEAR_CUSTOMER_FROM_EDIT = 'CLEAR_CUSTOMER_FROM_EDIT',
}
//TODO ADD TYPE TO RETURN RESPONSE
export interface CreateCustomerRequestAction
  extends Action<CustomerActionType.CREATE_CUSTOMER_REQUEST> {}

export interface CreateCustomerSuccessAction
  extends Action<CustomerActionType.CREATE_CUSTOMER_SUCCESS> {
  readonly successResponse: Customer;
}

export interface CreateCustomerFailureAction
  extends Action<CustomerActionType.CREATE_CUSTOMER_FAILURE> {
  readonly errorResponse: any;
}

export interface EditCustomerRequestAction
  extends Action<CustomerActionType.EDIT_CUSTOMER_REQUEST> {
  readonly values: Partial<Customer>;
}

export interface EditCustomerSuccessAction
  extends Action<CustomerActionType.EDIT_CUSTOMER_SUCCESS> {
  readonly successResponse: any;
}

export interface EditCustomerFailureAction
  extends Action<CustomerActionType.EDIT_CUSTOMER_FAILURE> {
  readonly errorResponse: any;
}

export interface GetCustomerListRequestAction
  extends Action<CustomerActionType.GET_CUSTOMER_LIST_REQUEST> {}

export interface GetCustomerListSuccessAction
  extends Action<CustomerActionType.GET_CUSTOMER_LIST_SUCCESS> {
  readonly successResponse: any;
}

export interface GetCustomerListFailureAction
  extends Action<CustomerActionType.GET_CUSTOMER_LIST_FAILURE> {
  readonly errorResponse: any;
}

export interface GetCustomerInfoRequest
  extends Action<CustomerActionType.GET_CUSTOMER_INFO_REQUEST> {}

export interface GetCustomerInfoSuccessAction
  extends Action<CustomerActionType.GET_CUSTOMER_INFO_SUCCESS> {
  readonly successResponse: Customer;
}

export interface GetCustomerInfoFailureAction
  extends Action<CustomerActionType.GET_CUSTOMER_INFO_FAILURE> {
  readonly errorResponse: any;
}

export interface SetCustomerForEditAction extends Action<CustomerActionType.SET_CUSTOMER_FOR_EDIT> {
  readonly customer: Customer;
}

export interface ClearCustomerEditAction
  extends Action<CustomerActionType.CLEAR_CUSTOMER_FROM_EDIT> {}

export type CustomerActions =
  | CreateCustomerRequestAction
  | CreateCustomerFailureAction
  | CreateCustomerSuccessAction
  | EditCustomerRequestAction
  | EditCustomerSuccessAction
  | EditCustomerFailureAction
  | GetCustomerListRequestAction
  | GetCustomerListSuccessAction
  | GetCustomerListFailureAction
  | SetCustomerForEditAction
  | ClearCustomerEditAction
  | GetCustomerInfoRequest
  | GetCustomerInfoSuccessAction
  | GetCustomerInfoFailureAction;
