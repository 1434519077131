/* Layout types and other constants */
//TODO MOVE LAYOUT TYPES IN A TYPES FOLDER
export const LAYOUT_VERTICAL = 'vertical';
export const LAYOUT_HORIZONTAL = 'topnav';
export const LAYOUT_DETACHED = 'detached';

export const LAYOUT_WIDTH_FLUID = 'fluid';
export const LAYOUT_WIDTH_BOXED = 'boxed';

export const LEFT_SIDEBAR_THEME_DEFAULT = 'default';
export const LEFT_SIDEBAR_THEME_LIGHT = 'light';
export const LEFT_SIDEBAR_THEME_DARK = 'dark';

export const LEFT_SIDEBAR_TYPE_FIXED = 'fixed';
export const LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed';
export const LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable';

export type LayoutTypes =
  | typeof LAYOUT_VERTICAL
  | typeof LAYOUT_HORIZONTAL
  | typeof LAYOUT_DETACHED;

export type LayoutWidthTypes = typeof LAYOUT_WIDTH_FLUID | typeof LAYOUT_WIDTH_BOXED;

export type SidebarThemesTypes =
  | typeof LEFT_SIDEBAR_THEME_DEFAULT
  | typeof LEFT_SIDEBAR_THEME_DARK
  | typeof LEFT_SIDEBAR_THEME_LIGHT;

export type SidebarTypes =
  | typeof LEFT_SIDEBAR_TYPE_FIXED
  | typeof LEFT_SIDEBAR_TYPE_CONDENSED
  | typeof LEFT_SIDEBAR_TYPE_SCROLLABLE;
