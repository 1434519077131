import React from 'react';
import { Redirect } from 'react-router-dom';

import PrivateRoute from 'routes/PrivateRoute';
import { Routes } from './types';
// apps
const CalendarApp = React.lazy(() => import('pages/apps/Calendar'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('pages/apps/Ecommerce/Sellers'));

//Home
const HomePage = React.lazy(() => import('pages/apps/Home/index'));
// Sessions
const ManageSessions = React.lazy(() => import('pages/apps/Users/index'));
const ManageGroupSessions = React.lazy(() => import('pages/apps/Groups/index'));

//Customers
const NewCustomer = React.lazy(() => import('pages/apps/Customer/ModifyCustomer'));
const CustomerList = React.lazy(() => import('pages/apps/Customer/CustomerList'));

//TODO Make enum for the naming of routes
// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to='/apps/home' />,
  route: PrivateRoute,
  roles: ['Admin'],
};

// const ecommerceAppRoutes = {
//   path: '/apps/ecommerce',
//   name: 'eCommerce',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'uil-store',
//   children: [
//     {
//       path: '/apps/ecommerce/products',
//       name: 'Products',
//       component: EcommerceProducts,
//       route: PrivateRoute,
//     },
//     {
//       path: '/apps/ecommerce/details',
//       name: 'Product Details',
//       component: ProductDetails,
//       route: PrivateRoute,
//     },
//     {
//       path: '/apps/ecommerce/orders',
//       name: 'Orders',
//       component: Orders,
//       route: PrivateRoute,
//     },
//     {
//       path: '/apps/ecommerce/order/details',
//       name: 'Order Details',
//       component: OrderDetails,
//       route: PrivateRoute,
//     },
//     {
//       path: '/apps/ecommerce/customers',
//       name: 'Customers',
//       component: Customers,
//       route: PrivateRoute,
//       roles: [process.env.REACT_APP_ADMIN_ROLES],
//     },
//     {
//       path: '/apps/ecommerce/shopping-cart',
//       name: 'Shopping Cart',
//       component: Cart,
//       route: PrivateRoute,
//       roles: [process.env.REACT_APP_ADMIN_ROLES],
//     },
//     {
//       path: '/apps/ecommerce/checkout',
//       name: 'Checkout',
//       component: Checkout,
//       route: PrivateRoute,
//       roles: [process.env.REACT_APP_ADMIN_ROLES],
//     },
//     {
//       path: '/apps/ecommerce/sellers',
//       name: 'Sellers',
//       component: Sellers,
//       route: PrivateRoute,
//       roles: [process.env.REACT_APP_ADMIN_ROLES],
//     },
//   ],
// };

const SessionsRoutes = {
  path: '/apps/sessions',
  name: 'Sessions',
  route: PrivateRoute,
  roles: [process.env.REACT_APP_ADMIN_ROLES],
  icon: 'uil-users-alt',
  children: [
    {
      path: Routes.MANAGE_GROUPS,
      name: 'Manage Groups',
      exact: true,
      component: ManageGroupSessions,
      route: PrivateRoute,
      roles: [process.env.REACT_APP_ADMIN_ROLES],
    },
    {
      path: Routes.MANAGE_SESSIONS,
      name: 'Manage Sessions',
      component: ManageSessions,
      route: PrivateRoute,
      roles: [process.env.REACT_APP_ADMIN_ROLES],
    },
  ],
};

const CustomerRoutes = {
  path: '/apps/customers',
  name: 'Customer',
  route: PrivateRoute,
  roles: [process.env.REACT_APP_ADMIN_ROLES, process.env.REACT_APP_SUB_ADMIN],
  icon: 'mdi-account-group',
  children: [
    {
      path: Routes.NEW_CUSTOMER,
      name: 'New Customer',
      exact: true,
      component: NewCustomer,
      route: PrivateRoute,
      roles: [process.env.REACT_APP_ADMIN_ROLES, process.env.REACT_APP_SUB_ADMIN],
    },
    {
      path: Routes.CUSTOMER_LIST,
      name: 'Customer List',
      exact: true,
      component: CustomerList,
      route: PrivateRoute,
      roles: [process.env.REACT_APP_ADMIN_ROLES, process.env.REACT_APP_SUB_ADMIN],
    },
  ],
};

const HomeRoutes = {
  path: '/apps/home',
  name: 'Home',
  route: PrivateRoute,
  roles: [process.env.REACT_APP_ADMIN_ROLES, process.env.REACT_APP_SUB_ADMIN],
  icon: 'uil-home-alt',
  component: HomePage,
};

const appRoutes = [HomeRoutes, SessionsRoutes, CustomerRoutes];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, ...appRoutes];

const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
export * from './types';
