import { ActionType, AppMenuAction } from './types';

type State = { value: boolean };

const initState = { value: false };

export const appMenuReducer = (state: State = initState, action: AppMenuAction) => {
  switch (action.type) {
    case ActionType.INIT_MENU:
      return action.payload;
    case ActionType.INIT_MENU_SUCCESS:
      return { ...state, ...action.payload };
    case ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION:
      return { ...state };
    case ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
