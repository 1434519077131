import { ThunkCommandFactory } from 'data-handling/adapters/redux';
import * as types from './types';
import * as actions from './actions';

export const getVPNList: ThunkCommandFactory<
  { customerId: string },
  types.GetVPNListFailureAction | types.GetVPNListSuccessAction | types.GetVPNListRequestAction
> = ({ customerId }) => async (dispatch, _getState, client) => {
  dispatch(actions.getVPNListRequest());
  try {
    const response = (await client.vpnClient.getList({ customerId })) as any;

    if (!response.detail) {
      dispatch(actions.getVPNListSuccess(response));
    } else {
      dispatch(actions.getVPNFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.getVPNFailure(err.message));
  }
};

export const editVPN: ThunkCommandFactory<
  { VPN: types.VPN },
  types.EditVPNFailureAction | types.EditVPNRequestAction | types.EditVPNSuccessAction
> = ({ VPN }) => async (dispatch, _getState, client) => {
  dispatch(actions.editVPNRequest());

  try {
    const response = (await client.vpnClient.update({
      zt_node_id: VPN.zt_node_id,
      camera_name: VPN.camera_name,
      zt_node_association: VPN.zt_node_association,
    })) as any;

    if (!response.detail) {
      dispatch(actions.editVPNSuccess(VPN));
      clearVPNForEdit()(dispatch, null, null);
    } else {
      dispatch(actions.editVPNFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.editVPNFailure(err.message));
  }
};

export const setVPNForEdit: ThunkCommandFactory<types.VPN, types.SetVPNForEditAction> = (
  VPN
) => async (dispatch, _getState) => {
  dispatch(actions.setVPNForEdit(VPN));
};

export const clearVPNForEdit: ThunkCommandFactory<void, types.ClearVPNEditAction> = () => async (
  dispatch,
  _getState
) => {
  dispatch(actions.clearVPNFromEdit());
};
