// @flow
import React from 'react';
import Routes from 'routes/Routes';
import { History } from 'history';
import ErrorManager from 'components/ErrorManager';
import ResourceManager from 'components/ResourcesManager';

import { ErrorBoundary } from 'components/ErrorBoundary';
// Themes
import './assets/scss/Saas.scss';
import { AuthProvider } from 'utils/AuthProvider';

interface AppProps {
  history: History;
}

const App = (): JSX.Element => (
  <ErrorBoundary>
    <AuthProvider>
      <ErrorManager></ErrorManager>
      <ResourceManager></ResourceManager>
      <Routes></Routes>
    </AuthProvider>
  </ErrorBoundary>
);

export default App;
