import { ThunkCommandFactory } from 'data-handling/adapters/redux';
import * as types from './types';
import * as actions from './actions';

export const getGroups: ThunkCommandFactory<
  void,
  types.GetGroupsFailureAction | types.GetGroupsRequestAction | types.GetGroupsSuccessAction
> = () => async (dispatch, _getState, client) => {
  dispatch(actions.getGroupsRequest());
  try {
    const groups = await client.groupClient.getAllGroups();

    dispatch(actions.getGroupsSuccess(groups.data));
  } catch (err) {
    dispatch(actions.getGroupsFailure());
  }
};
