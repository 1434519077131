import { HttpService } from '../../httpService';
import { keycloak } from 'helpers';

import { Customer } from 'data-handling/domain';
import { CustomerClient } from './types';
import { ResponseSuccess } from 'data-handling/infrastracture';

const makeCustomerAPIHandling = ({ httpService }: { httpService: HttpService }): CustomerClient => {
  const createCustomer = async ({
    customer_name,
    customer_street,
    new_cameras,
    customer_suite,
    customer_zip,
    customer_phone,
    customer_website,
    customer_poc_name,
    customer_poc_email,
    customer_poc_phone,
    customer_taxexempt,
    customer_ein,
    account_start_date,
    account_duration,
    account_total_contract,
    customer_storage_size,
  }: Partial<Customer>): Promise<ResponseSuccess<any>> => {
    const path = 'customer/create';
    const token = keycloak.token;

    const response = await httpService.POST<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      {
        customer_name,
        customer_street,
        new_cameras,
        customer_suite,
        customer_zip,
        customer_phone,
        customer_website,
        customer_poc_name,
        customer_poc_email,
        customer_poc_phone,
        customer_taxexempt,
        customer_ein,
        account_start_date,
        account_duration,
        account_total_contract,
        customer_storage_size,
      }
    );
    return response;
  };

  const editCustomer = async ({
    customer_name,
    customer_street,
    customer_suite,
    customer_zip,
    customer_phone,
    customer_website,
    customer_poc_name,
    customer_poc_email,
    customer_poc_phone,
    customer_taxexempt,
    customer_ein,
    account_start_date,
    account_duration,
    account_total_contract,
    customer_id,
    customer_storage_size,
  }: Partial<Customer>): Promise<ResponseSuccess<any>> => {
    const path = 'customer/edit';
    const token = keycloak.token;

    const response = await httpService.POST<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      {
        customer_name,
        customer_street,
        customer_suite,
        customer_zip,
        customer_phone,
        customer_website,
        customer_poc_name,
        customer_poc_email,
        customer_poc_phone,
        customer_taxexempt,
        customer_ein,
        account_start_date,
        account_duration,
        account_total_contract,
        customer_id,
        customer_storage_size,
      }
    );
    return response;
  };

  const getCustomerInfo = async ({ customer_id }): Promise<ResponseSuccess<Customer>> => {
    const path = 'customer/info';
    const token = keycloak.token;

    const response = await httpService.POST<Customer>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { customer_id }
    );
    return response;
  };

  const getCustomersList = async (): Promise<ResponseSuccess<Customer[]>> => {
    const path = 'customer/list';
    const token = keycloak.token;

    const response = await httpService.GET<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token
    );
    return response;
  };

  return { createCustomer, editCustomer, getCustomerInfo, getCustomersList };
};

export default makeCustomerAPIHandling;
