import { ActionFactory } from '../../adapters/redux';

import {
  GetActiveSessionsFailureAction,
  ActionType,
  GetActiveSessionsRequestAction,
  ActiveSessions,
  GetActiveSessionsSuccessAction,
  GetActiveSessionsByGroupIdFailureAction,
  GetActiveSessionsByGroupIdRequestAction,
  GetActiveSessionsByGroupIdSuccessAction,
} from './types';

export const getActiveSessionsRequest: ActionFactory<
  void,
  GetActiveSessionsRequestAction
> = () => ({
  type: ActionType.GET_ACTIVE_SESSIONS_REQUEST,
});

export const getActiveSessionsSuccess: ActionFactory<
  ActiveSessions,
  GetActiveSessionsSuccessAction
> = (activeSessions) => ({
  type: ActionType.GET_ACTIVE_SESSIONS_SUCCESS,
  activeSessions,
});

export const getActiveSessionsFailure: ActionFactory<
  void,
  GetActiveSessionsFailureAction
> = () => ({
  type: ActionType.GET_ACTIVE_SESSIONS_FAILURE,
});

export const getActiveSessionsByGroupIdFailure: ActionFactory<
  void,
  GetActiveSessionsByGroupIdFailureAction
> = () => ({
  type: ActionType.GET_ACTIVE_SESSIONS_BY_GROUP_ID_FAILURE,
});

export const getActiveSessionsByGroupIdRequest: ActionFactory<
  void,
  GetActiveSessionsByGroupIdRequestAction
> = () => ({
  type: ActionType.GET_ACTIVE_SESSIONS_BY_GROUP_ID_REQUEST,
});

export const getActiveSessionsByGroupIdSuccess: ActionFactory<
  ActiveSessions,
  GetActiveSessionsByGroupIdSuccessAction
> = (activeSessions) => ({
  type: ActionType.GET_ACTIVE_SESSIONS_BY_GROUP_ID_SUCCESS,
  activeSessions,
});
