import { connect } from 'react-redux';
import { resourcesUseCases, AppState, createLoadingSelector } from 'data-handling/domain';
import { DispatchProps, StateProps } from './types';

import ResourcesManager from './ResourcesManager';

const loadingSelector = createLoadingSelector(['GET_LICENCES_AND_ROLES']);

const mapDispatchToProps: DispatchProps = {
  handleGetLicenceAndRoles: resourcesUseCases.getLicencesAndRoles,
};

const mapStateToProps = ({ loading }: AppState): StateProps => ({
  isFetching: loadingSelector(loading),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesManager);
