import { Action } from 'redux';

export type CameraUser = {
  email: string;
  first: string;
  last: any;
  license_id: Array<number>;
  roles: Array<number>;
  user_id?: string;
  customer_id?: string;
};

export type CameraUserState = {
  cameraUser: Record<string, CameraUser>;
  editingCameraUser: CameraUser;
};

export enum CameraUserActionType {
  CREATE_CAMERA_USER_REQUEST = 'CREATE_CAMERA_USER_REQUEST',
  CREATE_CAMERA_USER_FAILURE = 'CREATE_CAMERA_USER_FAILURE',
  CREATE_CAMERA_USER_SUCCESS = 'CREATE_CAMERA_USER_SUCCESS',

  EDIT_CAMERA_USER_REQUEST = 'EDIT_CAMERA_USER_REQUEST',
  EDIT_CAMERA_USER_FAILURE = 'EDIT_CAMERA_USER_FAILURE',
  EDIT_CAMERA_USER_SUCCESS = 'EDIT_CAMERA_USER_SUCCESS',

  GET_CAMERA_USERS_LIST_REQUEST = 'GET_CAMERA_USERS_LIST_REQUEST',
  GET_CAMERA_USERS_LIST_SUCCESS = 'GET_CAMERA_USERS_LIST_SUCCESS',
  GET_CAMERA_USERS_LIST_FAILURE = 'GET_CAMERA_USERS_LIST_FAILURE',

  DELETE_CAMERA_USER_REQUEST = 'DELETE_CAMERA_USER_REQUEST',
  DELETE_CAMERA_USER_SUCCESS = 'DELETE_CAMERA_USER_SUCCESS',
  DELETE_CAMERA_USER_FAILURE = 'DELETE_CAMERA_USER_FAILURE',

  SET_CAMERA_USER_FOR_EDIT = 'SET_CAMERA_USER_FOR_EDIT',
  CLEAR_CAMERA_USER_FROM_EDIT = 'CLEAR_CAMERA_USER_FROM_EDIT',
}
export interface CreateCameraUserRequestAction
  extends Action<CameraUserActionType.CREATE_CAMERA_USER_REQUEST> {}

export interface CreateCameraUserSuccessAction
  extends Action<CameraUserActionType.CREATE_CAMERA_USER_SUCCESS> {
  readonly successResponse: CameraUser;
}

export interface CreateCameraUserFailureAction
  extends Action<CameraUserActionType.CREATE_CAMERA_USER_FAILURE> {
  readonly errorResponse: any;
}
//

export interface DeleteCameraUserRequestAction
  extends Action<CameraUserActionType.DELETE_CAMERA_USER_REQUEST> {}

export interface DeleteCameraUserSuccessAction
  extends Action<CameraUserActionType.DELETE_CAMERA_USER_SUCCESS> {
  readonly successResponse: CameraUser;
}

export interface DeleteCameraUserFailureAction
  extends Action<CameraUserActionType.DELETE_CAMERA_USER_FAILURE> {
  readonly errorResponse: any;
}

export interface EditCameraUserRequestAction
  extends Action<CameraUserActionType.EDIT_CAMERA_USER_REQUEST> {}

export interface EditCameraUserSuccessAction
  extends Action<CameraUserActionType.EDIT_CAMERA_USER_SUCCESS> {
  readonly successResponse: CameraUser;
}

export interface EditCameraUserFailureAction
  extends Action<CameraUserActionType.EDIT_CAMERA_USER_FAILURE> {
  readonly errorResponse: any;
}

export interface GetCameraUserListRequestAction
  extends Action<CameraUserActionType.GET_CAMERA_USERS_LIST_REQUEST> {}

export interface GetCameraUserListSuccessAction
  extends Action<CameraUserActionType.GET_CAMERA_USERS_LIST_SUCCESS> {
  readonly successResponse: CameraUser[];
}

export interface GetCameraUserListFailureAction
  extends Action<CameraUserActionType.GET_CAMERA_USERS_LIST_FAILURE> {
  readonly errorResponse: any;
}

export interface setCameraUserForEditAction
  extends Action<CameraUserActionType.SET_CAMERA_USER_FOR_EDIT> {
  readonly cameraUser: CameraUser;
}

export interface ClearCameraUserEditAction
  extends Action<CameraUserActionType.CLEAR_CAMERA_USER_FROM_EDIT> {}

export type CameraUserActions =
  | CreateCameraUserRequestAction
  | CreateCameraUserFailureAction
  | CreateCameraUserSuccessAction
  | EditCameraUserRequestAction
  | EditCameraUserSuccessAction
  | EditCameraUserFailureAction
  | GetCameraUserListRequestAction
  | GetCameraUserListSuccessAction
  | GetCameraUserListFailureAction
  | ClearCameraUserEditAction
  | setCameraUserForEditAction
  | DeleteCameraUserRequestAction
  | DeleteCameraUserSuccessAction
  | DeleteCameraUserFailureAction;
