import { Action } from 'redux';

export enum Orientation {
  down,
  up,
}

export type CameraProperties = {
  name?: string;
  fov?: string;
  cam_lat?: string;
  cam_lon?: string;
  cam_alt?: string;
  yaw?: string;
  pitch?: string;
  roll?: string;
  orientation?: keyof typeof Orientation;
  image_pan?: string;
  image_tilt?: string;
  icon_pan?: string;
  icon_tilt?: string;
  map_zoom?: string;
  marker_scale?: string;
  cesium_3d_object_url?: string;
  marker_opacity?: string;
  gps_enabled?: string;
  user_groups?: string;
  live_resolution?: string;
  live_ip?: string;
  live_profile?: any;
  recordings_ip?: string;
  rec_profile?: string;
  userName?: string;
  password?: string;
  hotspot?: string;
  camera_id?: string;
  customer_id?: string;
  num_cameras?: number;
};

export type CustomerCamerasState = {
  cameras: Record<string, CameraProperties>;
  editingCamera: CameraProperties;
};

export enum CameraActionType {
  CREATE_CAMERA_REQUEST = 'CREATE_CAMERA_REQUEST',
  CREATE_CAMERA_FAILURE = 'CREATE_CAMERA_FAILURE',
  CREATE_CAMERA_SUCCESS = 'CREATE_CAMERA_SUCCESS',

  EDIT_CAMERA_REQUEST = 'EDIT_CAMERA_REQUEST',
  EDIT_CAMERA_FAILURE = 'EDIT_CAMERA_FAILURE',
  EDIT_CAMERA_SUCCESS = 'EDIT_CAMERA_SUCCESS',

  GET_CAMERAS_LIST_REQUEST = 'GET_CAMERAS_LIST_REQUEST',
  GET_CAMERAS_LIST_SUCCESS = 'GET_CAMERAS_LIST_SUCCESS',
  GET_CAMERAS_LIST_FAILURE = 'GET_CAMERAS_LIST_FAILURE',

  DELETE_CAMERA_REQUEST = 'DELETE_CAMERA_REQUEST',
  DELETE_CAMERA_SUCCESS = 'DELETE_CAMERA_SUCCESS',
  DELETE_CAMERA_FAILURE = 'DELETE_CAMERA_FAILURE',

  SET_CAMERA_FOR_EDIT = 'SET_CAMERA_FOR_EDIT',
  CLEAR_CAMERA_FROM_EDIT = 'CLEAR_CAMERA_FROM_EDIT',
}
export interface CreateCameraRequestAction extends Action<CameraActionType.CREATE_CAMERA_REQUEST> {}

export interface CreateCameraSuccessAction extends Action<CameraActionType.CREATE_CAMERA_SUCCESS> {
  readonly successResponse: CameraProperties;
}

export interface CreateCameraFailureAction extends Action<CameraActionType.CREATE_CAMERA_FAILURE> {
  readonly errorResponse: any;
}

export interface EditCameraRequestAction extends Action<CameraActionType.EDIT_CAMERA_REQUEST> {}

export interface EditCameraSuccessAction extends Action<CameraActionType.EDIT_CAMERA_SUCCESS> {
  readonly successResponse: CameraProperties;
}

export interface EditCameraFailureAction extends Action<CameraActionType.EDIT_CAMERA_FAILURE> {
  readonly errorResponse: any;
}

export interface GetCameraListRequestAction
  extends Action<CameraActionType.GET_CAMERAS_LIST_REQUEST> {}

export interface GetCameraListSuccessAction
  extends Action<CameraActionType.GET_CAMERAS_LIST_SUCCESS> {
  readonly successResponse: CameraProperties[];
}

export interface GetCameraListFailureAction
  extends Action<CameraActionType.GET_CAMERAS_LIST_FAILURE> {
  readonly errorResponse: any;
}

export interface DeleteRequestAction extends Action<CameraActionType.DELETE_CAMERA_REQUEST> {}

export interface DeleteCameraSuccessAction extends Action<CameraActionType.DELETE_CAMERA_SUCCESS> {
  readonly successResponse: any;
}

export interface DeleteCameraFailureAction extends Action<CameraActionType.DELETE_CAMERA_FAILURE> {
  readonly errorResponse: any;
}

export interface SetCameraForEditAction extends Action<CameraActionType.SET_CAMERA_FOR_EDIT> {
  readonly camera: CameraProperties;
}

export interface ClearCameraFromEditAction
  extends Action<CameraActionType.CLEAR_CAMERA_FROM_EDIT> {}

export type CameraActions =
  | CreateCameraRequestAction
  | CreateCameraFailureAction
  | CreateCameraSuccessAction
  | EditCameraRequestAction
  | EditCameraSuccessAction
  | EditCameraFailureAction
  | GetCameraListRequestAction
  | GetCameraListSuccessAction
  | GetCameraListFailureAction
  | DeleteRequestAction
  | DeleteCameraSuccessAction
  | DeleteCameraFailureAction
  | SetCameraForEditAction
  | ClearCameraFromEditAction;
