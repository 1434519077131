import { ActionFactory } from 'data-handling/adapters/redux';

import { ResourceActionType } from './types';

import * as Types from './types';

export const getLicencesAndRolesRequest: ActionFactory<
  void,
  Types.GetLicencesAndRolesRequestAction
> = () => ({
  type: ResourceActionType.GET_LICENCES_AND_ROLES_REQUEST,
});

export const getLicencesAndRolesSuccess: ActionFactory<
  any,
  Types.GetLicencesAndRolesSuccessAction
> = (successResponse) => ({
  type: ResourceActionType.GET_LICENCES_AND_ROLES_SUCCESS,
  successResponse,
});

export const getLicencesAndRolesFailure: ActionFactory<
  any,
  Types.GetLicencesAndRolesFailureAction
> = (errorResponse) => ({
  type: ResourceActionType.GET_LICENCES_AND_ROLES_FAILURE,
  errorResponse,
});
