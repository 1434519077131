import { ActionFactory } from '../../../adapters/redux';

import {
  ActionType,
  InitMenuAction,
  InitMenuSuccessAction,
  ChangeActiveMenuFromLocationAction,
  ChangeActiveMenuFromLocationSuccessAction,
} from './types';

export const initMenu: ActionFactory<string, InitMenuAction> = () => ({
  type: ActionType.INIT_MENU,
  payload: {},
});

export const initMenuSuccess: ActionFactory<any, InitMenuSuccessAction> = (menuItems) => ({
  type: ActionType.INIT_MENU_SUCCESS,
  payload: { menuItems },
});

export const changeActiveMenuFromLocation: ActionFactory<
  string,
  ChangeActiveMenuFromLocationAction
> = () => ({
  type: ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION,
  payload: {},
});

export const changeActiveMenuFromLocationSuccess: ActionFactory<
  any,
  ChangeActiveMenuFromLocationSuccessAction
> = (activatedMenuItemIds) => ({
  type: ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS,
  payload: { activatedMenuItemIds },
});
