import React from 'react';
import { ResourcesManagerProps } from './types';
import Loader from 'components/Loader';
import useAuth from 'hooks/useAuth';

//This compnent is only used to load the desired data like roles and licences from the server
const ResourcesManager = ({
  isFetching,
  handleGetLicenceAndRoles,
}: ResourcesManagerProps): JSX.Element => {
  const { token } = useAuth();

  React.useEffect(() => {
    if (token) {
      handleGetLicenceAndRoles();
    }
  }, [token]);

  if (isFetching) return <Loader></Loader>;

  return <div></div>;
};

export default ResourcesManager;
