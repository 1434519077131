import { ThunkCommandFactory } from 'data-handling/adapters/redux';
import * as types from './types';
import * as actions from './actions';
//TODO REFACTOR RESPONSE SUCCESS TYPE TO HAVE ARGUMENT DETAIL

export const getCustomerList: ThunkCommandFactory<
  void,
  | types.GetCustomerListFailureAction
  | types.GetCustomerListSuccessAction
  | types.GetCustomerListRequestAction
> = () => async (dispatch, _getState, client) => {
  dispatch(actions.getCustomerListRequest());
  try {
    const response = (await client.customerClient.getCustomersList()) as any;

    if (!response.detail) {
      dispatch(actions.getCustomerListSuccess(response));
    } else {
      dispatch(actions.getCustomerFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.getCustomerFailure(err.message));
  }
};

export const createCustomer: ThunkCommandFactory<
  { customer: types.Customer; onSuccessCallback: () => void },
  | types.CreateCustomerRequestAction
  | types.CreateCustomerFailureAction
  | types.CreateCustomerSuccessAction
> = ({ customer, onSuccessCallback }) => async (dispatch, _getState, client) => {
  dispatch(actions.createCustomerRequest());
  try {
    const response = (await client.customerClient.createCustomer(customer)) as any;
    if (!response.detail) {
      //TODO THIS NEED TO RETURN FOR SURE THE ID OF THE CUSTOMER OTHERWISE IS NOT GOING TO WORK
      onSuccessCallback();
      customer.customer_id = response.customer_id || null;
      dispatch(actions.createCustomerSuccess(customer));
      //When new user is created make sure that we add the customer as edit customer
      setCustomerForEdit(customer)(dispatch, null, null);
      getCustomerList()(dispatch, _getState, client);
    } else {
      dispatch(actions.createCustomerFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.createCustomerFailure(err.message));

    // dispatch(actions.createCustomerSuccess(customer));
    // setCustomerForEdit(customer)(dispatch, null, null);
  }
};

export const editCustomer: ThunkCommandFactory<
  { customer: types.Customer; onSuccessCallback: () => void },
  | types.EditCustomerFailureAction
  | types.EditCustomerRequestAction
  | types.EditCustomerSuccessAction
> = ({ customer, onSuccessCallback }) => async (dispatch, _getState, client) => {
  dispatch(actions.editCustomerRequest(customer));
  try {
    const response = (await client.customerClient.editCustomer(customer)) as any;
    if (response && response.detail) {
      dispatch(actions.editCustomerFailure(response.detail[0].msg));
      return;
    }

    setCustomerForEdit(customer)(dispatch, null, null);
    getCustomerList()(dispatch, _getState, client);
    dispatch(actions.editCustomerSuccess(customer));
  } catch (err) {
    dispatch(actions.editCustomerFailure(err.message));
  }
};

export const getCustomerInfo: ThunkCommandFactory<
  { customer_id: string },
  | types.GetCustomerInfoFailureAction
  | types.GetCustomerInfoSuccessAction
  | types.GetCustomerInfoRequest
> = ({ customer_id }) => async (dispatch, _getState, client) => {
  dispatch(actions.getCustomerInfoRequest());
  try {
    /**Double check that we have the customer id at redux otherwise do not perform the request
     * this might happen due to ansychronoys data
     */
    if (!_getState().customers.editingCustomer) {
      dispatch(actions.getCustomerInfoFailure(''));
      return;
    }
    const response = (await client.customerClient.getCustomerInfo({ customer_id })) as any;
    if (!response.detail) {
      dispatch(actions.getCustomerInfoSuccess(response[0]));
      // setCustomerForEdit(response.data)(dispatch, null, null);
    } else {
      dispatch(actions.getCustomerInfoFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.getCustomerInfoFailure(err.message));
  }
};

export const setCustomerForEdit: ThunkCommandFactory<
  types.Customer,
  types.SetCustomerForEditAction
> = (customer) => async (dispatch, _getState) => {
  dispatch(actions.setCustomerForEdit(customer));
};

export const clearCustomerForEdit: ThunkCommandFactory<
  void,
  types.ClearCustomerEditAction
> = () => async (dispatch, _getState) => {
  dispatch(actions.clearCustomerFromEdit());
};
