import _ from 'lodash';
export function convertObjectToArray<F>(obj: Record<string, F>): F[] {
  if (Object.keys(obj).length === 0 || !obj) return [];
  const copy: { [key: string]: any } = {
    ...obj,
  };
  const copyKeys = Object.keys(copy);
  const copyObject = copyKeys.reduce((array, currentValue) => {
    array.push(copy[currentValue]);
    return array;
  }, []);
  return copyObject;
}

export function convertLicencesAndRolesToViewObject(licences = []): { licences: any; roles: any } {
  if (Object.keys(licences).length === 0 || !licences) return { licences: [], roles: {} };

  const copy = _.cloneDeep(licences);
  const copiedLicences = copy.reduce(
    (resouces, currentResource) => {
      resouces.licences.push({
        value: currentResource.license_id,
        label: currentResource.license_name,
      });
      resouces.roles[currentResource.license_id] = currentResource.roles.reduce(
        (allRoles, currentRole) => {
          allRoles.push({ value: currentRole.role_id, label: currentRole.role_name });
          return allRoles;
        },
        []
      );
      return resouces;
    },
    {
      licences: [],
      roles: {},
    }
  );
  return copiedLicences;
}

export function convertValueFromSelectFieldToDTO(array = []): [] {
  if (Object.keys(array).length === 0 || !array) return [];

  const copy = _.cloneDeep(array);
  const copiedLicences = copy.reduce((dtoArray, currentValue) => {
    dtoArray.push(currentValue.value);
    return dtoArray;
  }, []);
  return copiedLicences;
}

export function convertValuefromDTOToSelectFieldView({
  value,
  mappings,
}: {
  value: string | number | Array<any>;
  mappings: Array<{ value: string | number; label: string }>;
}): any {
  if (!mappings || !value) return null;

  if (!Array.isArray(value)) {
    const filteredMapping = mappings.filter((mapping) => {
      return mapping.value == value;
    });
    return filteredMapping.length > 0 ? filteredMapping[0] : null;
  } else {
    if (value.length < 1) return [];
    const filteredMapping = mappings.filter((mapping) => {
      return value.some((currentValue) => mapping.value === currentValue);
    });
    return filteredMapping;
  }
}
