import { ThunkCommandFactory } from 'data-handling/adapters/redux';
import * as types from './types';
import * as actions from './actions';

export const getLicencesAndRoles: ThunkCommandFactory<
  void,
  | types.GetLicencesAndRolesFailureAction
  | types.GetLicencesAndRolesRequestAction
  | types.GetLicencesAndRolesSuccessAction
> = () => async (dispatch, _getState, client) => {
  dispatch(actions.getLicencesAndRolesRequest());
  try {
    const response = await client.resourcesClient.getLicenceAndRoles();
    if (!response.detail) {
      dispatch(actions.getLicencesAndRolesSuccess(response));
    } else {
      dispatch(actions.getLicencesAndRolesFailure('Licence and roles ' + response.detail));
    }
  } catch (err) {
    dispatch(actions.getLicencesAndRolesFailure(err.message));
  }
};
