import { createSelector, Selector } from 'reselect';
import { memoize } from 'lodash';

import { AppState, ActiveSessions, ActiveSession } from 'data-handling/domain';

export const activeSessions: Selector<AppState, ActiveSessions> = (state) => state.activeSessions;

export const activeSessionArray = createSelector<AppState, ActiveSessions, any>(
  activeSessions,
  (activeSessions) => {
    const activeSessionKeys = Object.keys(activeSessions);
    const activeSessionTranformArray = activeSessionKeys.reduce((array, activeSession) => {
      array.push(activeSessions[activeSession]);
      return array;
    }, []);

    return activeSessionTranformArray;
  }
);

export const getActiveSessionByGroupName = createSelector<AppState, ActiveSession[], any>(
  activeSessionArray,
  (activeSessions) =>
    memoize((groupName) =>
      activeSessions.filter((activeSession) => activeSession.group === groupName)
    )
);
