import { HttpService } from '../../httpService';
import { GroupClient } from './types';
import { Group } from 'data-handling/domain';
import { keycloak } from 'helpers';

import { ResponseSuccess } from 'data-handling/infrastracture';

const makeGroupAPIHandling = ({ httpService }: { httpService: HttpService }): GroupClient => {
  const getAllGroups = async (): Promise<ResponseSuccess<Group[]>> => {
    const path = 'groups';
    const token = keycloak.token;

    const allGroups = await httpService.GET<Group[]>(
      `${process.env.REACT_APP_API_BASE_URL}/${path}`,
      token
    );
    return allGroups;
  };

  return { getAllGroups };
};

export default makeGroupAPIHandling;
