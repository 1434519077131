import { ActionFactory } from 'data-handling/adapters/redux';

import { CameraUserActionType } from './types';

import * as Types from './types';

export const createCameraUserRequest: ActionFactory<
  void,
  Types.CreateCameraUserRequestAction
> = () => ({
  type: CameraUserActionType.CREATE_CAMERA_USER_REQUEST,
});

export const createCameraUserSuccess: ActionFactory<
  Types.CameraUser,
  Types.CreateCameraUserSuccessAction
> = (successResponse) => ({
  type: CameraUserActionType.CREATE_CAMERA_USER_SUCCESS,
  successResponse,
});

export const createCameraUserFailure: ActionFactory<any, Types.CreateCameraUserFailureAction> = (
  errorResponse
) => ({
  type: CameraUserActionType.CREATE_CAMERA_USER_FAILURE,
  errorResponse,
});

export const editCameraUserRequest: ActionFactory<
  void,
  Types.EditCameraUserRequestAction
> = () => ({
  type: CameraUserActionType.EDIT_CAMERA_USER_REQUEST,
});

export const editCameraUserSuccess: ActionFactory<any, Types.EditCameraUserSuccessAction> = (
  successResponse
) => ({
  type: CameraUserActionType.EDIT_CAMERA_USER_SUCCESS,
  successResponse,
});

export const editCameraUserFailure: ActionFactory<any, Types.EditCameraUserFailureAction> = (
  errorResponse
) => ({
  type: CameraUserActionType.EDIT_CAMERA_USER_FAILURE,
  errorResponse,
});

export const getCameraUserListRequest: ActionFactory<
  void,
  Types.GetCameraUserListRequestAction
> = () => ({
  type: CameraUserActionType.GET_CAMERA_USERS_LIST_REQUEST,
});

export const getCameraUserListSuccess: ActionFactory<
  Types.CameraUser[],
  Types.GetCameraUserListSuccessAction
> = (successResponse) => ({
  type: CameraUserActionType.GET_CAMERA_USERS_LIST_SUCCESS,
  successResponse,
});

export const getCameraUserFailure: ActionFactory<any, Types.GetCameraUserListFailureAction> = (
  errorResponse
) => ({
  type: CameraUserActionType.GET_CAMERA_USERS_LIST_FAILURE,
  errorResponse,
});

//

export const deleteCameraUserRequest: ActionFactory<
  void,
  Types.DeleteCameraUserRequestAction
> = () => ({
  type: CameraUserActionType.DELETE_CAMERA_USER_REQUEST,
});

export const deleteCameraUserSuccess: ActionFactory<any, Types.DeleteCameraUserSuccessAction> = (
  successResponse
) => ({
  type: CameraUserActionType.DELETE_CAMERA_USER_SUCCESS,
  successResponse,
});

export const deleteCameraUserFailure: ActionFactory<any, Types.DeleteCameraUserFailureAction> = (
  errorResponse
) => ({
  type: CameraUserActionType.DELETE_CAMERA_USER_FAILURE,
  errorResponse,
});
export const setCameraUserForEdit: ActionFactory<
  Types.CameraUser,
  Types.setCameraUserForEditAction
> = (cameraUser) => ({
  type: CameraUserActionType.SET_CAMERA_USER_FOR_EDIT,
  cameraUser,
});

export const clearCameraUserFromEdit: ActionFactory<
  void,
  Types.ClearCameraUserEditAction
> = () => ({
  type: CameraUserActionType.CLEAR_CAMERA_USER_FROM_EDIT,
});
