import { connect } from 'react-redux';

import { AppState } from 'data-handling/domain';
import { StateProps } from './types';
import ErrorManager from './ErrorManager';

const mapStateToProps = ({ errorResponse }: AppState): StateProps => ({
  errorResponse: errorResponse.error,
});

export default connect(mapStateToProps, null)(ErrorManager);
