import { GroupActionType, GroupAction, Group } from './types';

const initialState: Group[] = [];

export const groupReducer = (state = initialState, action: GroupAction): Group[] => {
  switch (action.type) {
    case GroupActionType.GET_GROUPS_SUCCESS:
      return action.groups;
    default:
      return state;
  }
};
