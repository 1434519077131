import { ThunkCommandFactory } from 'data-handling/adapters/redux';
import * as types from './types';
import * as actions from './actions';
//TODO REFACTOR RESPONSE SUCCESS TYPE TO HAVE ARGUMENT DETAIL

export const getCameraList: ThunkCommandFactory<
  { customerId: string },
  | types.GetCameraListFailureAction
  | types.GetCameraListSuccessAction
  | types.GetCameraListRequestAction
> = ({ customerId }) => async (dispatch, _getState, client) => {
  dispatch(actions.getCameraListRequest());
  try {
    const response = (await client.cameraClient.getCameras({ customerId })) as any;
    if (!response.detail) {
      dispatch(actions.getCameraListSuccess(response));
    } else {
      dispatch(actions.getCameraFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.getCameraFailure(err.message));
  }
};

export const createCamera: ThunkCommandFactory<
  types.CameraProperties,
  | types.CreateCameraRequestAction
  | types.CreateCameraFailureAction
  | types.CreateCameraSuccessAction
> = (camera) => async (dispatch, _getState, client) => {
  dispatch(actions.createCameraRequest());

  try {
    const cameraWithCustomerId = {
      ...camera,
      customer_id: _getState().customers.editingCustomer.customer_id,
    };
    const response = (await client.cameraClient.createCamera(cameraWithCustomerId)) as any;
    if (!response.detail) {
      // dispatch(actions.createCameraSuccess(response));
      getCameraList({ customerId: _getState().customers.editingCustomer.customer_id })(
        dispatch,
        null,
        client
      );
      clearCameraForEdit()(dispatch, null, null);
    } else {
      dispatch(actions.createCameraFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.createCameraFailure(err.message));
  }
};

export const editCamera: ThunkCommandFactory<
  types.CameraProperties,
  types.EditCameraFailureAction | types.EditCameraRequestAction | types.EditCameraSuccessAction
> = (camera) => async (dispatch, _getState, client) => {
  dispatch(actions.editCameraRequest());

  try {
    const cameraWithCustomerId = {
      ...camera,
      customer_id: _getState().customers.editingCustomer.customer_id,
    };
    const response = (await client.cameraClient.updateCamera(cameraWithCustomerId)) as any;

    if (!response.detail) {
      dispatch(actions.editCameraSuccess(camera));
      clearCameraForEdit()(dispatch, null, null);
    } else {
      dispatch(actions.editCameraFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.editCameraFailure(err.message));
  }
};

export const deleteCamera: ThunkCommandFactory<
  { camera_id },
  types.DeleteCameraFailureAction | types.DeleteRequestAction | types.DeleteCameraSuccessAction
> = ({ camera_id }) => async (dispatch, _getState, client) => {
  dispatch(actions.deleteCameraRequest());
  try {
    const response = (await client.cameraClient.deleteCamera({
      camera_id,
      customer_id: _getState().customers.editingCustomer.customer_id,
    })) as any;
    if (!response.detail) {
      dispatch(
        actions.deleteCameraSuccess({
          camera_id,
        })
      );
    } else {
      dispatch(actions.deleteCameraFailure(response.detail[0].msg));
    }
  } catch (err) {
    dispatch(actions.deleteCameraFailure(err.message));
  }
};

export const setCameraForEdit: ThunkCommandFactory<
  types.CameraProperties,
  types.SetCameraForEditAction
> = (camera) => async (dispatch, _getState, client) => {
  dispatch(actions.setCameraForEdit(camera));
};

export const clearCameraForEdit: ThunkCommandFactory<
  void,
  types.ClearCameraFromEditAction
> = () => async (dispatch, _getState, client) => {
  dispatch(actions.clearCameraFromEdit());
};
