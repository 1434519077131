import { Action } from 'redux';

export type VPN = {
  zt_node_id?: string;
  camera_name: string;
  zt_node_association?: string;
};

export type VPNState = {
  editingVPN: VPN;
  vpn: Record<string, VPN>;
};

export enum VPNActionType {
  EDIT_VPN_REQUEST = 'EDIT_VPN_REQUEST',
  EDIT_VPN_FAILURE = 'EDIT_VPN_FAILURE',
  EDIT_VPN_SUCCESS = 'EDIT_VPN_SUCCESS',

  GET_VPN_LIST_REQUEST = 'GET_VPN_LIST_REQUEST',
  GET_VPN_LIST_SUCCESS = 'GET_VPN_LIST_SUCCESS',
  GET_VPN_LIST_FAILURE = 'GET_VPN_LIST_FAILURE',

  SET_VPN_FOR_EDIT = 'SET_VPN_FOR_EDIT',
  CLEAR_VPN_FROM_EDIT = 'CLEAR_VPN_FROM_EDIT',
}

export interface EditVPNRequestAction extends Action<VPNActionType.EDIT_VPN_REQUEST> {}

export interface EditVPNSuccessAction extends Action<VPNActionType.EDIT_VPN_SUCCESS> {
  readonly successResponse: VPN;
}

export interface EditVPNFailureAction extends Action<VPNActionType.EDIT_VPN_FAILURE> {
  readonly errorResponse: any;
}

export interface GetVPNListRequestAction extends Action<VPNActionType.GET_VPN_LIST_REQUEST> {}

export interface GetVPNListSuccessAction extends Action<VPNActionType.GET_VPN_LIST_SUCCESS> {
  readonly successResponse: VPN[];
}

export interface GetVPNListFailureAction extends Action<VPNActionType.GET_VPN_LIST_FAILURE> {
  readonly errorResponse: any;
}

export interface SetVPNForEditAction extends Action<VPNActionType.SET_VPN_FOR_EDIT> {
  readonly VPN: VPN;
}

export interface ClearVPNEditAction extends Action<VPNActionType.CLEAR_VPN_FROM_EDIT> {}

export type VPNActions =
  | EditVPNRequestAction
  | EditVPNSuccessAction
  | EditVPNFailureAction
  | GetVPNListRequestAction
  | GetVPNListSuccessAction
  | GetVPNListFailureAction
  | SetVPNForEditAction
  | ClearVPNEditAction;
