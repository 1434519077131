import { Action } from 'redux';

export interface ActiveSession {
  id: string;
  username: string;
  userId: string;
  ipAdress: string;
  start: number;
  lastAccess: number;
  client: string;
  group: string;
}

export interface ActiveSessions {
  [id: string]: ActiveSession;
}
export enum ActionType {
  GET_ACTIVE_SESSIONS_REQUEST = 'GET_ACTIVE_SESSIONS_REQUEST',
  GET_ACTIVE_SESSIONS_FAILURE = 'GET_ACTIVE_SESSIONS_FAILURE',
  GET_ACTIVE_SESSIONS_SUCCESS = 'GET_ACTIVE_SESSIONS_SUCCESS',
  GET_ACTIVE_SESSIONS_BY_GROUP_ID_SUCCESS = 'GET_ACTIVE_SESSIONS_BY_GROUP_ID_SUCCESS',
  GET_ACTIVE_SESSIONS_BY_GROUP_ID_REQUEST = 'GET_ACTIVE_SESSIONS_BY_GROUP_ID_REQUEST',
  GET_ACTIVE_SESSIONS_BY_GROUP_ID_FAILURE = 'GET_ACTIVE_SESSIONS_BY_GROUP_ID_FAILURE',
}

export interface GetActiveSessionsRequestAction
  extends Action<ActionType.GET_ACTIVE_SESSIONS_REQUEST> {}

export interface GetActiveSessionsSuccessAction
  extends Action<ActionType.GET_ACTIVE_SESSIONS_SUCCESS> {
  readonly activeSessions: ActiveSessions;
}

export interface GetActiveSessionsFailureAction
  extends Action<ActionType.GET_ACTIVE_SESSIONS_FAILURE> {}

export interface GetActiveSessionsByGroupIdFailureAction
  extends Action<ActionType.GET_ACTIVE_SESSIONS_BY_GROUP_ID_FAILURE> {}

export interface GetActiveSessionsByGroupIdRequestAction
  extends Action<ActionType.GET_ACTIVE_SESSIONS_BY_GROUP_ID_REQUEST> {}

export interface GetActiveSessionsByGroupIdSuccessAction
  extends Action<ActionType.GET_ACTIVE_SESSIONS_BY_GROUP_ID_SUCCESS> {
  readonly activeSessions: ActiveSessions;
}

export type ActiveSessionsAction =
  | GetActiveSessionsRequestAction
  | GetActiveSessionsSuccessAction
  | GetActiveSessionsFailureAction
  | GetActiveSessionsByGroupIdFailureAction
  | GetActiveSessionsByGroupIdRequestAction
  | GetActiveSessionsByGroupIdSuccessAction;
