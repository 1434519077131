import React from 'react';
import { ErrorManagerProps } from './types';
import Alert from './Alert';

const ErrorManager = ({ errorResponse, duration = 10000 }: ErrorManagerProps): JSX.Element => {
  const timeoutRef = React.useRef<NodeJS.Timeout | number>();
  const [isOpen, setIsOpen] = React.useState(false);

  const destroyAlert = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (errorResponse) {
      setIsOpen(true);
      timeoutRef.current = setTimeout(destroyAlert, duration);
    }

    return () => clearTimeout(timeoutRef.current as number);
  }, [errorResponse]);

  if (!isOpen) return <React.Fragment></React.Fragment>;
  return <Alert error={errorResponse}></Alert>;
};

export default ErrorManager;
