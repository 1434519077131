import { HttpService } from './types';

const fetchHttpService = (): HttpService => {
  async function POST<T>(url = '', token, data = {}): Promise<T> {
    // Default options are marked with *
    try {
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      });

      const responseJson = response.status !== 204 ? await response.json() : '';

      return responseJson; // parses JSON response into native JavaScript objects
    } catch (err) {
      throw new Error(err);
    }
  }

  async function GET<T>(url = '', token): Promise<T> {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
    });

    const request = new Request(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers,
    });

    try {
      // Default options are marked with *
      const response = await fetch(request);
      return response.json(); // parses JSON response into native JavaScript objects
    } catch (err) {
      throw new Error(err);
    }
  }

  return { POST, GET };
};

export default fetchHttpService;
