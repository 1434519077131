import { ActionFactory } from 'data-handling/adapters/redux';

import { CustomerActionType } from './types';

import * as Types from './types';

export const createCustomerRequest: ActionFactory<
  void,
  Types.CreateCustomerRequestAction
> = () => ({
  type: CustomerActionType.CREATE_CUSTOMER_REQUEST,
});

export const createCustomerSuccess: ActionFactory<any, Types.CreateCustomerSuccessAction> = (
  successResponse
) => ({
  type: CustomerActionType.CREATE_CUSTOMER_SUCCESS,
  successResponse,
});

export const createCustomerFailure: ActionFactory<any, Types.CreateCustomerFailureAction> = (
  errorResponse
) => ({
  type: CustomerActionType.CREATE_CUSTOMER_FAILURE,
  errorResponse,
});

export const editCustomerRequest: ActionFactory<Types.Customer, Types.EditCustomerRequestAction> = (
  values
) => ({
  type: CustomerActionType.EDIT_CUSTOMER_REQUEST,
  values,
});

export const editCustomerSuccess: ActionFactory<any, Types.EditCustomerSuccessAction> = (
  successResponse
) => ({
  type: CustomerActionType.EDIT_CUSTOMER_SUCCESS,
  successResponse,
});

export const editCustomerFailure: ActionFactory<any, Types.EditCustomerFailureAction> = (
  errorResponse
) => ({
  type: CustomerActionType.EDIT_CUSTOMER_FAILURE,
  errorResponse,
});

export const getCustomerListRequest: ActionFactory<
  void,
  Types.GetCustomerListRequestAction
> = () => ({
  type: CustomerActionType.GET_CUSTOMER_LIST_REQUEST,
});

export const getCustomerListSuccess: ActionFactory<any, Types.GetCustomerListSuccessAction> = (
  successResponse
) => ({
  type: CustomerActionType.GET_CUSTOMER_LIST_SUCCESS,
  successResponse,
});

export const getCustomerFailure: ActionFactory<any, Types.GetCustomerListFailureAction> = (
  errorResponse
) => ({
  type: CustomerActionType.GET_CUSTOMER_LIST_FAILURE,
  errorResponse,
});
//

export const getCustomerInfoRequest: ActionFactory<void, Types.GetCustomerInfoRequest> = () => ({
  type: CustomerActionType.GET_CUSTOMER_INFO_REQUEST,
});

export const getCustomerInfoSuccess: ActionFactory<any, Types.GetCustomerInfoSuccessAction> = (
  successResponse
) => ({
  type: CustomerActionType.GET_CUSTOMER_INFO_SUCCESS,
  successResponse,
});

export const getCustomerInfoFailure: ActionFactory<any, Types.GetCustomerInfoFailureAction> = (
  errorResponse
) => ({
  type: CustomerActionType.GET_CUSTOMER_INFO_FAILURE,
  errorResponse,
});

export const setCustomerForEdit: ActionFactory<Types.Customer, Types.SetCustomerForEditAction> = (
  customer
) => ({
  type: CustomerActionType.SET_CUSTOMER_FOR_EDIT,
  customer,
});

export const clearCustomerFromEdit: ActionFactory<void, Types.ClearCustomerEditAction> = () => ({
  type: CustomerActionType.CLEAR_CUSTOMER_FROM_EDIT,
});
