import { ActionType, ActiveSessionsAction, ActiveSessions } from './types';

const initialState: ActiveSessions = {};

export const activeSessionsReducer = (
  state = initialState,
  action: ActiveSessionsAction
): ActiveSessions => {
  switch (action.type) {
    case ActionType.GET_ACTIVE_SESSIONS_SUCCESS:
      return action.activeSessions;
    case ActionType.GET_ACTIVE_SESSIONS_BY_GROUP_ID_SUCCESS:
      return { ...state, ...action.activeSessions };
    default:
      return state;
  }
};
