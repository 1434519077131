import { HttpService } from '../../httpService';
import { keycloak } from 'helpers';

import { CameraProperties } from 'data-handling/domain';
import { CamerasClient } from './types';
import { ResponseSuccess } from 'data-handling/infrastracture';

const makeCamerasAPIHandling = ({ httpService }: { httpService: HttpService }): CamerasClient => {
  const createCamera = async ({
    ...cameraProps
  }: CameraProperties): Promise<ResponseSuccess<any[]>> => {
    const path = 'camera/create';
    const token = keycloak.token;

    const response = await httpService.POST<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { num_cameras: +cameraProps.num_cameras, customer_id: cameraProps.customer_id }
    );
    return response;
  };

  const deleteCamera = async ({ camera_id, customer_id }): Promise<ResponseSuccess<any[]>> => {
    const path = 'camera/delete';
    const token = keycloak.token;

    const response = await httpService.POST<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { camera_id, customer_id }
    );
    return response;
  };

  const updateCamera = async (camera: CameraProperties): Promise<ResponseSuccess<any[]>> => {
    const path = 'camera/update';
    const token = keycloak.token;

    const response = await httpService.POST<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { ...camera }
    );
    return response;
  };

  const getCameras = async ({
    customerId,
  }: {
    customerId: string;
  }): Promise<ResponseSuccess<any[]>> => {
    const path = 'camera/list';
    const token = keycloak.token;

    const response = await httpService.POST<any[]>(
      `${process.env.REACT_APP_API_FORM}/${path}`,
      token,
      { customer_id: customerId }
    );
    return response;
  };

  return { getCameras, updateCamera, deleteCamera, createCamera };
};

export default makeCamerasAPIHandling;
