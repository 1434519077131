import { createSelector, Selector } from 'reselect';
import { memoize, cloneDeep } from 'lodash';

import { AppState, Customer } from 'data-handling/domain';
import { convertValuefromDTOToSelectFieldView } from 'helpers';

export const editingCustomerSelector: Selector<AppState['customers'], Customer> = (customers) =>
  customers.editingCustomer;

const MAPPING_TAX_EXEMPT = [
  { value: 'False', label: 'No' },
  { value: 'True', label: 'Yes' },
];
const MAPPING_TIME_VALID = [
  { value: '1', label: '1 Month' },
  { value: '2', label: '2 Months' },
  { value: '3', label: '3 Months' },
  { value: '4', label: '4 Months' },
  { value: '5', label: '5 Months' },
  { value: '6', label: '6 Months' },
  { value: '7', label: '7 Months' },
  { value: '8', label: '8 Months' },
  { value: '9', label: '9 Months' },
  { value: '10', label: '10 Months' },
  { value: '11', label: '11 Months' },
  { value: '12', label: '12 Months' },
];
export const editingCustomerWithView = createSelector<AppState['customers'], Customer, any>(
  editingCustomerSelector,
  (editingCustomer) => {
    if (!editingCustomer) return null;
    const cloneEditingCustomer = cloneDeep(editingCustomer);
    const convertTime = () => {
      try {
        const accountStartDate =
          cloneEditingCustomer && cloneEditingCustomer?.account_start_date
            ? new Date(+cloneEditingCustomer?.account_start_date * 1000)
            : null;

        const accountTotalContract =
          cloneEditingCustomer && cloneEditingCustomer?.account_total_contract
            ? new Date(+cloneEditingCustomer.account_total_contract * 1000)
            : null;

        return {
          account_start_date: accountStartDate,
          account_total_contract: accountTotalContract,
        };
      } catch (err) {}
    };

    return {
      ...cloneEditingCustomer,
      customer_taxexempt: convertValuefromDTOToSelectFieldView({
        mappings: MAPPING_TAX_EXEMPT,
        value: cloneEditingCustomer.customer_taxexempt,
      }),
      account_duration: convertValuefromDTOToSelectFieldView({
        mappings: MAPPING_TIME_VALID,
        value: cloneEditingCustomer.account_duration,
      }),
      axisMSE: cloneEditingCustomer?.customer_generated_urls?.axisMSE || '',
      recording_api: cloneEditingCustomer?.customer_generated_urls?.recording_api || '',
      ws1: cloneEditingCustomer?.customer_generated_urls?.ws1 || '',
      ws2: cloneEditingCustomer?.customer_generated_urls?.ws2 || '',
      ws3: cloneEditingCustomer?.customer_generated_urls?.ws3 || '',
      ws4: cloneEditingCustomer?.customer_generated_urls?.ws4 || '',
      ...convertTime(),
    };
  }
);
