import { ActionType, LayoutAction } from './types';
import * as layoutConstants from 'constants/layout';

const INIT_STATE = {
  layoutType: layoutConstants.LAYOUT_VERTICAL,
  layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
  leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT,
  leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
  showRightSidebar: false,
};

export const layoutReducer = (state = INIT_STATE, action: LayoutAction) => {
  switch (action.type) {
    case ActionType.CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case ActionType.CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case ActionType.CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case ActionType.CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload,
      };
    case ActionType.TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
      };
    case ActionType.SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case ActionType.HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    default:
      return state;
  }
};
