import React from 'react';
import useKeyCloak from 'hooks/useKeycloak';

export interface AuthContextValue {
  isAuthenticated: boolean | undefined;
  token: string | null;
  userInfo: UserInfo | null;
  signOut: () => void;
}
export type UserInfo = {
  id: string;
  roles?: string[];
};

const AuthContext = React.createContext<AuthContextValue>({
  isAuthenticated: false,
  token: null,
  userInfo: null,
  signOut: () => {
    return;
  },
});

const AuthProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [roles, setRoles] = React.useState<Array<string>>([]);

  // called when get success from keycloak login
  const loginSuccess = React.useCallback((token: any, roles: string[]) => {
    setAuthenticated(true);
    setToken(token);
    setRoles(roles);
  }, []);

  //called when we get an error in the keycloak login later this function can be populated to display an error message
  const loginError = React.useCallback(() => {
    setAuthenticated(false);
    setToken(null);
  }, []);

  const { init: keyCloakInit, authClient } = useKeyCloak({
    successCallback: loginSuccess,
    errorCallback: loginError,
  });

  const initAuthentication = React.useCallback(() => {
    keyCloakInit({ onLoad: 'login-required', checkLoginIframe: false });
  }, []);

  const signOut = React.useCallback(() => {
    authClient.logout();
  }, [authClient]);

  React.useEffect(() => {
    initAuthentication();
  }, [initAuthentication]);

  const userInfo = React.useMemo<UserInfo>(() => {
    if (isAuthenticated && token) {
      return { id: '1', roles };
    }
    // if no prev session exists and the user is not logged-in, then there is no userInfo
    return null;
  }, [isAuthenticated, token, roles]);

  const contextValue = React.useMemo(
    () => ({
      isAuthenticated,
      token,
      userInfo,
      signOut,
    }),
    [isAuthenticated, token, userInfo, roles]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const MemoizedAuthProvider = React.memo(AuthProvider);

export { AuthContext, MemoizedAuthProvider as AuthProvider };
