//TODO ADD ERROR HANDLING
import { combineReducers } from 'redux';
import { CustomerActionType, CustomerActions, Customer, CustomerState } from './types';
import produce from 'immer';

// const testEditingCustomer: Customer = {
//   customer_id: '1',
//   customer_name: 'adas',
//   customer_street: 'asdasd',
//   customer_suite: 'asdasd',
//   customer_zip: 10,
//   customer_phone: 'asdasd',
//   customer_website: 'asdsd',
//   customer_poc_name: 'asdasd',
//   customer_poc_email: 'alex@hotmail.com',
//   customer_poc_phone: 'asdasd',
//   customer_taxexempt: 'False',
//   customer_ein: 'asdasd',
//   new_cameras: 1,
//   account_start_date: '1483221600',
//   account_duration: null,
//   account_total_contract: '1483221600',
//   customer_generated_urls: {
//     axisMSE: 'www.axis',
//     recording_api: 'www.recording',
//     ws1: 'ws1',
//     ws2: 'ws2',
//     ws3: 'ws3',
//     ws4: 'ws4',
//   },
// };

const initialState: Record<string, Customer> = {};

const customers = produce((draft, action: CustomerActions) => {
  switch (action.type) {
    case CustomerActionType.GET_CUSTOMER_LIST_SUCCESS:
      action.successResponse.forEach((customer) => {
        draft[customer.customer_id] = customer;
      });
      return draft;
    case CustomerActionType.CREATE_CUSTOMER_SUCCESS:
      draft[action.successResponse.customer_id] = action.successResponse;
      return draft;
    case CustomerActionType.EDIT_CUSTOMER_SUCCESS:
      draft[action.successResponse.customer_id] = action.successResponse;
      return draft;
  }
}, initialState);

// export const customers = (state = initialState, action: CustomerActions): Customer[] => {
//   switch (action.type) {
//     case CustomerActionType.GET_CUSTOMER_LIST_SUCCESS:
//       return action.successResponse;
//     case CustomerActionType.CREATE_CUSTOMER_SUCCESS:
//       return { ...state, [action.successResponse.customer_name]: { ...action.successResponse } };
//     default:
//       return state;
//   }
// };

export const editingCustomer = (state = null, action: CustomerActions): Customer => {
  switch (action.type) {
    case CustomerActionType.GET_CUSTOMER_INFO_SUCCESS:
      return action.successResponse;
    case CustomerActionType.SET_CUSTOMER_FOR_EDIT:
      return action.customer;
    case CustomerActionType.CLEAR_CUSTOMER_FROM_EDIT:
      return null;
    default:
      return state;
  }
};

export const customerReducer = combineReducers<CustomerState>({
  editingCustomer,
  customers,
});
