import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { useAuth } from 'hooks';

//TODO MAKE ROLES ENUM
type PrivateRouteProps = {
  component: React.FunctionComponent<RouteComponentProps>;
  roles?: any;
};

const PrivateRoute = ({ component: Component, roles, ...rest }: PrivateRouteProps): JSX.Element => {
  const { isAuthenticated, token, userInfo } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <div></div>;
          // not logged in so redirect to login page with the return url
          // return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
        }

        const loggedInUser = userInfo;
        // check if route is restricted by role
        if (roles && !roles.some((r) => loggedInUser?.roles.includes(r))) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/apps/home' }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
