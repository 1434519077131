import { ActiveSessions } from './session';
import { Group } from './groups';
import { CustomerState } from './customers';
import { CameraUserState } from './cameras-users';
import { CustomerCamerasState } from './cameras';
import { VPNState } from './vpn';
import { ResourcesState } from './resources';
import { ErrorResponse } from './ui/error';

export interface AppState {
  readonly activeSessions: ActiveSessions;
  readonly groups: Group[];
  readonly Layout: any;
  readonly AppMenu: any;
  readonly loading: any;
  readonly customers: CustomerState;
  readonly customerCamerasUsers: CameraUserState;
  readonly customerCameras: CustomerCamerasState;
  readonly customerVPN: VPNState;
  readonly resources: ResourcesState;
  readonly errorResponse: ErrorResponse;
}

export enum Client {
  WEB = 'WEB',
  TAGLOCK = 'TAGLOCK',
}
