import { ActionFactory } from 'data-handling/adapters/redux';

import {
  GetGroupsFailureAction,
  GroupActionType,
  GetGroupsSuccessAction,
  Group,
  GetGroupsRequestAction,
} from './types';

export const getGroupsRequest: ActionFactory<void, GetGroupsRequestAction> = () => ({
  type: GroupActionType.GET_GROUPS_REQUEST,
});

export const getGroupsSuccess: ActionFactory<Array<Group>, GetGroupsSuccessAction> = (groups) => ({
  type: GroupActionType.GET_GROUPS_SUCCESS,
  groups,
});

export const getGroupsFailure: ActionFactory<void, GetGroupsFailureAction> = () => ({
  type: GroupActionType.GET_GROUPS_FAILURE,
});
