import React, { lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { allFlattenRoutes as routes } from './index';
import * as layoutConstants from 'constants/layout';

// All layouts/containers
import AuthLayout from 'layouts/Auth';
import VerticalLayout from 'layouts/Vertical';
import HorizontalLayout from 'layouts/Horizontal';
import DetachedLayout from 'layouts/Detached';

import { useAuth } from 'hooks';

import { Routes as RouterRoutes } from './types';

const GroupDetailsPage = lazy(() => import('pages/apps/GroupDetails'));
const EditCustomer = React.lazy(() => import('pages/apps/Customer/ModifyCustomer'));

type RouteProps = {
  layout: { layoutType: layoutConstants.LayoutTypes; showRightSidebar: boolean };
  user: any;
};

const Routes = ({ layout, user, ...rest }: RouteProps): JSX.Element => {
  const [Layout, setLayout] = React.useState<any>(VerticalLayout);
  const { isAuthenticated, userInfo } = useAuth();

  const getLayout = () => {
    if (!isAuthenticated) return setLayout(AuthLayout);
    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        setLayout(HorizontalLayout);
        break;
      case layoutConstants.LAYOUT_DETACHED:
        setLayout(DetachedLayout);
        break;
      default:
        setLayout(VerticalLayout);
        break;
    }
  };

  React.useEffect(() => {
    getLayout();
  }, [getLayout]);

  if (
    userInfo?.roles?.includes(process.env.REACT_APP_ADMIN_ROLES) ||
    userInfo?.roles?.includes(process.env.REACT_APP_SUB_ADMIN)
  ) {
    return (
      <BrowserRouter>
        <Layout layout={layout} {...rest}>
          <Switch>
            {routes.map((route, index) => {
              return (
                !route.children && (
                  <route.route
                    key={index}
                    path={route.path}
                    roles={route.roles}
                    exact={route.exact}
                    component={route.component}
                  ></route.route>
                )
              );
            })}
            <Route
              path='/apps/groups/manage-groups/details/:groupid'
              component={GroupDetailsPage}
            />
            <Route path={`${RouterRoutes.EDIT_CUSTOMER}/:groupid`} component={EditCustomer} />
          </Switch>
        </Layout>
      </BrowserRouter>
    );
  } else {
    return <div>Not authenticated</div>;
  }
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    user: { name: 'alex' },
  };
};

function areEqual(prevProps, nextProps) {
  const oldLayout = { ...prevProps.layout };
  delete oldLayout.showRightSidebar;
  const newLayout = { ...nextProps.layout };
  delete newLayout.showRightSidebar;
  return (
    JSON.stringify(oldLayout) === JSON.stringify(newLayout) ||
    JSON.stringify(prevProps.user) === JSON.stringify(nextProps.user)
  );
}

export default React.memo(connect(mapStateToProps, null)(Routes), areEqual);
