import { Action } from 'redux';

export enum ActionType {
  CHANGE_LAYOUT = 'CHANGE_LAYOUT',
  CHANGE_LAYOUT_WIDTH = 'CHANGE_LAYOUT_WIDTH',
  CHANGE_SIDEBAR_THEME = 'CHANGE_SIDEBAR_THEME',
  CHANGE_SIDEBAR_TYPE = 'CHANGE_SIDEBAR_TYPE',
  TOGGLE_RIGHT_SIDEBAR = 'TOGGLE_RIGHT_SIDEBAR',
  SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR',
  HIDE_RIGHT_SIDEBAR = 'HIDE_RIGHT_SIDEBAR',
}

export interface ChangeLayoutAction extends Action<ActionType.CHANGE_LAYOUT> {
  readonly payload?: string | null;
}

export interface ChangeLayoutWidthAction extends Action<ActionType.CHANGE_LAYOUT_WIDTH> {
  readonly payload?: string | null;
}

export interface ChangeSidebarThemeAction extends Action<ActionType.CHANGE_SIDEBAR_THEME> {
  readonly payload?: string | null;
}

export interface ChangeSidebarTypeAction extends Action<ActionType.CHANGE_SIDEBAR_TYPE> {
  readonly payload?: string | null;
}

export interface ToggleRightSidebarAction extends Action<ActionType.TOGGLE_RIGHT_SIDEBAR> {
  readonly payload?: string | null;
}

export interface ShowRightSidebarAction extends Action<ActionType.SHOW_RIGHT_SIDEBAR> {
  readonly payload?: string | null;
}

export interface HideRightSidebarAction extends Action<ActionType.HIDE_RIGHT_SIDEBAR> {
  readonly payload?: string | null;
}

export type LayoutAction =
  | ChangeLayoutAction
  | ChangeLayoutWidthAction
  | ChangeSidebarThemeAction
  | ChangeSidebarTypeAction
  | ToggleRightSidebarAction
  | ShowRightSidebarAction
  | HideRightSidebarAction;
