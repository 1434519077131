//TODO USE IMMER
//TODO ADD ERROR HANDLING
import { combineReducers } from 'redux';
import { ResourceActionType, ResourcesActions, Resources, ResourcesState } from './types';
import { convertLicencesAndRolesToViewObject } from 'helpers';

export const resources = (
  state = { licences: null, roles: null },
  action: ResourcesActions
): Resources => {
  switch (action.type) {
    case ResourceActionType.GET_LICENCES_AND_ROLES_SUCCESS:
      return {
        ...state,
        ...convertLicencesAndRolesToViewObject(action.successResponse),
      };
    default:
      return state;
  }
};

export const resourcesReducer = combineReducers<ResourcesState>({
  resources,
});
