import { normalize, NormalizedSchema } from 'normalizr';

import { ThunkCommandFactory } from 'data-handling/adapters/redux';
import { ActiveSessions } from 'data-handling/domain';

import * as types from './types';
import * as actions from './actions';

import sessionListSchema from './schema';

export const getActiveSessions: ThunkCommandFactory<
  void,
  | types.GetActiveSessionsFailureAction
  | types.GetActiveSessionsRequestAction
  | types.GetActiveSessionsSuccessAction
> = () => async (dispatch, _getState, client) => {
  dispatch(actions.getActiveSessionsRequest());
  try {
    const activeSessions = await client.activeSessionsClient.getAllActiveSessions();

    const normalizedSession = normalize(activeSessions, sessionListSchema).entities
      .activeSessions as ActiveSessions;

    dispatch(actions.getActiveSessionsSuccess(normalizedSession));
  } catch (err) {
    dispatch(actions.getActiveSessionsFailure());
  }
};

export const getActiveSessionsByGroupId: ThunkCommandFactory<
  { groupId: string },
  | types.GetActiveSessionsByGroupIdFailureAction
  | types.GetActiveSessionsByGroupIdRequestAction
  | types.GetActiveSessionsByGroupIdSuccessAction
> = ({ groupId }) => async (dispatch, _getState, client) => {
  dispatch(actions.getActiveSessionsByGroupIdRequest());
  try {
    const activeSessions = await client.activeSessionsClient.getActiveSessionsByGroupId({
      groupId,
    });

    const normalizedSessions = normalize(activeSessions, sessionListSchema).entities
      .activeSessions as ActiveSessions;
    dispatch(actions.getActiveSessionsByGroupIdSuccess(normalizedSessions));
  } catch (err) {
    dispatch(actions.getActiveSessionsByGroupIdFailure());
  }
};
